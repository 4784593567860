// === React Imports ===
import React from 'react';

// === Custom Imports ===
import { getIFrameYoutubeURL } from '../utils/helpers';
import '../styles/components/YoutubeVideo.scss';

type YoutubeVideoProps = {
  className?: string;
  url: string;
}

const YoutubeVideo = ({ className, url }: YoutubeVideoProps): JSX.Element => (
    <iframe 
        width={"100%"}
        height="400"
        src={getIFrameYoutubeURL(url)}
        className={`youtubeVideoIFrame ${className}`}
    />
);

export default YoutubeVideo;
