// === React Imports ===
import React from 'react';

// === Custom Imports ===
import DefaultText from '../components/default/DefaultText';
import DefaultButton from '../components/default/DefaultButton';
import MainFullBgImg from '../assets/images/main-full-bg-01.png';
import Api from '../utils/api';
import { Routes } from '../utils/routes';
import '../styles/screens/UnsubscribeScreen.scss';

type UnsubscribeScreenProps = any;
type UnsubscribeScreenState = {
    loading: boolean;
    email: string
};

class UnsubscribeScreen extends React.Component<UnsubscribeScreenProps, UnsubscribeScreenState> {
    constructor(props: UnsubscribeScreenProps) {
        super(props);
        this.state = {
            loading: true,
            email: ''
        }
    }

    componentDidMount(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (!token) {
            window.location.assign(Routes.HOME);
            return;
        }

        Api.unsubscribeUserFromEmails(token)
            .then((res) => {
                this.setState({ loading: false, email: res.data.email });
            })
            .catch((err) => {
                console.log(err);
                window.location.assign(Routes.HOME);
                return;
            })
    }

    render(): JSX.Element {
        const { loading, email } = this.state;

        if (loading) return <div />;

        return (
            <div>
                <img className="mainBgImg" src={MainFullBgImg} alt="" />
                <div className="unsubscribeScreenContainer">
                    <DefaultText className="unsubscribeScreenContainerHeader" text="You have successfully unsubscribed." bold />
                    <DefaultText className="unsubscribeScreenContainerBlurb" text={`${email} has been unsubscribed from further email notifications.`} />
                    <DefaultButton className="unsubscribeScreenContainerButton" text="Return to home" onClick={(): void => { window.location.href = `${window.location.origin}${Routes.HOME}` }} />
                </div>
            </div>
        )
    }
}

export default UnsubscribeScreen;
