export const Errors = {
    UnknownException: 'UnknownException',
    UnauthorizedException: 'UnauthorizedException',
    URLNotRetreivableException: 'URLNotRetreivableException',
    InvalidURLException: 'InvalidURLException',
    InvalidEmailSyntaxException: 'InvalidEmailSyntaxException',
    UserAlreadyInvitedException: 'UserAlreadyInvitedException',
    MaxNumInvitesException: 'MaxNumInvitesException',
    InviteSelfException: 'InviteSelfException',
    NoUsersMatchQuery: 'NoUsersMatchQuery',
    UserAlreadyAskedForInviteException: 'UserAlreadyAskedForInviteException',
};