// === React Imports ===
import React from 'react';

// === Custom Imports ===
import DefaultText from '../components/default/DefaultText';
import YoutubeVideo from '../components/YoutubeVideo';
import { Memory } from '../utils/types';
import { getURLTitle, isYoutubeVideoURL } from '../utils/helpers';
import '../styles/components/MemoryCard.scss';

type MemoryCardProps = {
    memory: Memory;
    className?: string;
    onClick?: () => void;
}
  
const MemoryCard = ({ memory, className, onClick }: MemoryCardProps): JSX.Element => (
    <div className={`memoryCard ${className}`} onClick={(): void => { onClick && onClick() }}>
        <div>
            <DefaultText 
                className="memoryCardURL" 
                text={memory.url ? getURLTitle(memory.url) : 'image'} 
            />
            {memory.title && <DefaultText className="memoryCardTitle" text={memory.title.slice()} bold />}
        </div>
        {memory.img && (
            <div
                className="memoryCardImgContainer"
                style={{
                    backgroundImage: `url(${memory.publicImg ? memory.img : memory.imgBlobURL})`
                }}
            />
        )}
        {memory.url && isYoutubeVideoURL(memory.url) && (
            <YoutubeVideo url={memory.url} />
        )}
        <DefaultText className="memoryCardTopic" text={memory.topic} />
    </div>
);
  
export default MemoryCard;