import React from 'react';
import DefaultText from '../default/DefaultText';
import DefaultTextArea from '../default/DefaultTextArea';
import DefaultButton from '../default/DefaultButton';
import Api from '../../utils/api';
import '../../styles/components/modals/CreateMemoryModal.scss';
import '../../styles/components/modals/FeedbackModal.scss';

type FeedbackModalProps = {
    onClose: () => void;
};

type FeedbackModalState = {
    feedback: string;
    message: string;
};

class FeedbackModal extends React.Component<FeedbackModalProps, FeedbackModalState> {
    constructor(props: FeedbackModalProps) {
        super(props);
        this.state = {
            feedback: '',
            message: ''
        }
    }

    onFeedbackInput = (feedback: string): void => {
        this.setState({ feedback, message: '' });
    }

    onSendFeedback = (): void => {
        const { feedback } = this.state;
        Api.sendFeedback(feedback)
            .then(() => {
                this.setState({ feedback: '', message: 'Thank you. Your feedback has been sent.' })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    render(): JSX.Element {
        const { onClose } = this.props;
        const { feedback, message } = this.state;

        return (
            <div>
                <div className="createMemoryModalBackground" onClick={onClose} />
                <div className="createMemoryModalContainer">
                    <div className="createMemoryModalContentContainer">
                        <DefaultText className="feedbackModalHeader" text="Send Feedback" bold />
                        <DefaultText
                            className="feedbackModalBlurb"
                            text="Please help us make Memento a better service for all. Any features you would like to see added? Any inconveniences? Write them down and we'll be more than happy to take a look."
                        />
                        <DefaultTextArea
                            className="feedbackModalTextArea"
                            value={feedback}
                            onValueChange={this.onFeedbackInput}
                            maxLength={1000}
                        />
                        <DefaultText className="feedbackModalMsg" text={message} />
                        <div className="createMemoryModalActionButtonContainer">
                            <DefaultButton 
                                className="feedbackModalButton" 
                                text="Cancel" 
                                onClick={onClose}
                            />
                            <DefaultButton 
                                className="feedbackModalButton" 
                                text="Send" 
                                disabled={!feedback} 
                                onClick={this.onSendFeedback}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeedbackModal;
  