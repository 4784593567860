import axios, { AxiosInstance } from 'axios';
import { LoggedInUser, Memory } from './types';
import { Routes } from './routes';

/**
 * Namespace module that handles API requests
 */
class Api {
    endpoint: string;
    transport: AxiosInstance;
    loggedInUser: LoggedInUser;

    constructor() {
        this.endpoint = window.location.hostname === 'localhost' ? 'http://localhost:3005/api/v1' : 'https://memento-me.com/api/v1';
        this.transport = axios.create({
            withCredentials: true, // Needed for the token
        });
        this.loggedInUser = {
            email: '',
            profile_image: '',
            given_name: '',
            family_name: '',
            invitedEmails: '',
        };

        this.transport.interceptors.response.use(
            res => res,
            err => {
                if (err.response && err.response.status === 403 && (window.location.pathname !== Routes.LOGIN) ) {
                    window.location.assign(Routes.LOGIN);
                    return;
                }

                throw err;
            }
        );
    }

    /**
     * Sets currently logged in user
     * @param user - logged in user
     */
    setLoggedInUser(user: LoggedInUser): void {
        this.loggedInUser = user;
    }

    /**
     * Checks if current JWT token is still valid
     */
    checkJWTToken(): Promise<any> {
        return this.transport.get(`${this.endpoint}/user/me`);
    }

    /**
     * Get topics for url
     * @param url - url
     */
    getURLTopicsAndTitle(url: string): Promise<any> {
        return this.transport.post(`${this.endpoint}/topic/url`, { url });
    }

    /**
     * Get all available pre-defined topics
     */
    getAllTopics(): Promise<any> {
        return this.transport.get(`${this.endpoint}/topic/all`);
    }

    /**
     * Creates new url memory
     * @param url 
     * @param title 
     * @param topic 
     * @param tags 
     * @param hiddenTags 
     * @param img 
     */
    createURLMemory(url: string, title: string, topic: string, tags?: string, hiddenTags? : string, img?: string): Promise<any> {
        return this.transport.post(`${this.endpoint}/memory/create-url`, { url, title, topic, tags, hiddenTags, img });
    }

    /**
     * Creates new image memory
     * @param formData 
     */
    createImageMemory(formData: any): Promise<any> {
        return this.transport.post(`${this.endpoint}/memory/create-img`, formData);
    }

    /**
     * Gets all of user memories (with pagination)
     * @param page 
     * @param size 
     * @param sortBy 
     */
    getMemories(page: number, size: number, sortBy: string): Promise<any> {
        return this.transport.get(`${this.endpoint}/memory?page=${page}&size=${size}&sortBy=${sortBy}`);
    } 

    /**
     * Get invidual memory
     * @param memoryId 
     */
    getMemory(memoryId: number): Promise<any> {
        return this.transport.get(`${this.endpoint}/memory/${memoryId}`);
    }

    /**
     * Edit memory
     * @param memory 
     */
    editMemory(memory: Memory): Promise<any> {
        return this.transport.put(`${this.endpoint}/memory/${memory.id}`, { ...memory, tags: memory.tags.join(','), hiddenTags: memory.hiddenTags.join(',') });
    }

    /**
     * Deletes memory
     * @param memoryId 
     */
    deleteMemory(memoryId: number): Promise<any> {
        return this.transport.delete(`${this.endpoint}/memory/${memoryId}`);
    }

    /**
     * Get file
     * @param fileName - file name 
     */
    getFile(fileName: string, headers: any): Promise<any> {
        return this.transport.get(`${this.endpoint}/file/${fileName}`, headers);
    }

    /**
     * Search memories
     * @param searchTerm 
     * @param page 
     * @param size 
     * @param sortBy 
     */
    searchMemory(searchTerm: string, page: number, size: number): Promise<any> {
        return this.transport.post(`${this.endpoint}/memory/search?page=${page}&size=${size}`, {
            search: searchTerm
        });
    }

    /**
     * Get image topic and labels
     * @param data - form data
     */
    getImageTopicAndLabels(data: any): Promise<any> {
        return this.transport.post(`${this.endpoint}/topic/img`, data);
    }

    /**
     * Send invite to user
     * @param email - email of user to invite
     */
    inviteUser(email: string): Promise<any> {
        return this.transport.post(`${this.endpoint}/user/invite`, { email });
    }

    /**
     * Creates new chrome extension JWT Token
     */
    createChromeExtensionToken = (): Promise<any> => {
        return this.transport.post(`${this.endpoint}/user/new-chrome-token`);
    }

    /**
     * Request invite
     */
    requestInvite = (email: string, noEmail?: boolean): Promise<any> => {
        return this.transport.post(`${this.endpoint}/user/request-invite${noEmail ? '?no_email=true' : ''}`, { email });
    }

    getInviteNotAcceptedUsers = (): Promise<any> => {
        return this.transport.get(`${this.endpoint}/user/?hasBeenInvited=true&hasAcceptedInvitation=false`);
    }

    getNotInvitedUsers = (): Promise<any> => {
        return this.transport.get(`${this.endpoint}/user/?hasBeenInvited=false`);
    }

    sendCustomEmail = (queryOptions: any, subject: string, customHTML: string): Promise<any> => {
        return this.transport.post(`${this.endpoint}/email/custom`, { queryOptions, subject, customHTML })
    }

    unsubscribeUserFromEmails = (token: string): Promise<any> => {
        return axios.post(`${this.endpoint}/user/unsubscribe-email`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    sendFeedback = (feedback: string): Promise<any> => {
        return this.transport.post(`${this.endpoint}/email/feedback`, { feedback });
    }
}

export default new Api();