/**
 * Opens url in new tab
 * @param url - url to open
 */
export function openInNewTab(url: string): void {
    window.open(url, '_blank');
}

/**
 * Converts percentage value to string percentage display
 * @param value 0 - 1 percentage
 */
export function toPercentage(value: number): string {
    return `${Math.round(value * 100)}%`;
}

/**
 * Gets base url from url
 * @param url 
 */
export function getBaseURL(url: string | undefined): string {
    if (!url) return '';
    const newUrl = url.replace('http://', '').replace('https://', '');
    const urlTokens = newUrl.split('/');
    return urlTokens[0];
}

/**
 * Gets website title (Ex. "google" from "www.google.com")
 * @param url 
 */
export function getURLTitle(url: string): string {
    const baseURL = getBaseURL(url);
    const tokens = baseURL.split('.');
    return tokens.length >= 3 ? tokens[1] : tokens[0];
}

/**
 * Get video id for youtube video
 *
 * @param {string} url - youtube video url
 * @return {string} - youtube video id
 */
function getVideoId (url: string) {
    const videoRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const videoTokens = url.match(videoRegex);
    if (videoTokens && videoTokens.length > 1) {
        const videoId = videoTokens[1];
        return videoId;
    }

    return '';
}

/**
 * Checks if url is youtube
 * @param url - url
 */
export function isYoutubeVideoURL(url: string | undefined): boolean {
    if (!url) return false;
    const baseUrlSame = getBaseURL(url) === 'www.youtube.com';
    const containsVideoId = getVideoId(url) !== '';
    return baseUrlSame && containsVideoId;
}

/**
 * Gets url that iframe accepts
 * @param url - youtube url
 */
export function getIFrameYoutubeURL(url: string): string {
    const videoRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matched = url.match(videoRegex);
    if (matched && matched.length >= 1) {
        const videoId = matched[1];
        return `https://www.youtube.com/embed/${videoId}`
    }
    
    return url;
}

function getRandomInt(min: number, max: number): number {
    return Math.floor((Math.random() * (max - min)) + min);
}

export function getRandomCardBgImg(): string {
    return `./card-bgs/memento-card-bgs-${`0${getRandomInt(0, 24)}`.slice(-2)}.png`;
}