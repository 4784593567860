// === React Imports ===
import React from 'react';

// === Custom Imports ===
import '../styles/components/Loader.scss';

const Loader = (): JSX.Element => (
    <div className="gooey">
        <span className="dot"></span>
        <div className="dots">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
);

export default Loader;
