import React from 'react';
import * as Scroll from 'react-scroll';

import DefaultText from '../components/default/DefaultText';
import DefaultButton from '../components/default/DefaultButton';
import Blob from '../components/svg/Blob';
import Api from '../utils/api';
import mementoLogo from '../assets/images/memento-logo.png';
import MainFullBgImg from '../assets/images/main-full-bg-01.png';
import FaceIllustration from '../assets/images/face-illustration.png';
import PreviewImg from '../assets/images/preview.png';
import { BlobColors } from '../utils/constants';
import { Errors } from '../utils/errors';
import '../styles/screens/LoginScreen.scss';
import DefaultTextInput from '../components/default/DefaultTextInput';

type LoginScreenProps = any;
type LoginScreenState = {
    signUpStep: number;
    signUpEmail: string;
    signUpEmailError: string;
};

const blob1Color = BlobColors[Math.floor(Math.random() * BlobColors.length)];
const blob2Color = BlobColors[Math.floor(Math.random() * BlobColors.length)];
const blob3Color = BlobColors[Math.floor(Math.random() * BlobColors.length)];
const blob4Color = BlobColors[Math.floor(Math.random() * BlobColors.length)];

class LoginScreen extends React.Component<LoginScreenProps, LoginScreenState> {
    constructor(props: LoginScreenProps) {
        super(props);
        this.state = {
            signUpStep: 0,
            signUpEmail: '',
            signUpEmailError: ''
        }
    }

    /**
     * Google sign in
     */
    onGoogleSignIn = (): void => {
        window.location.href = `${Api.endpoint}/sso/google`;
    }

    goToHeader = (header: string): void => {
        Scroll.scroller.scrollTo(header, { smooth: true, offset: -100, duration: 500 })
    }

    onEmailInput = (text: string): void => {
        this.setState({ signUpEmail: text });
    }

    onRequestInvite = (): void => {
        const { signUpEmail } = this.state;
        Api.requestInvite(signUpEmail)
            .then(() => {
                this.setState({ signUpStep: 2 });
            })
            .catch((err) => {
                console.log(err);
                if (err.response && err.response.status && err.response.data && err.response.status === 400) {
                    if (err.response.data.response === Errors.InvalidEmailSyntaxException) {
                        this.setState({ signUpEmailError: 'Please provide a valid email.' });
                        return;
                    } else if (err.response.data.response === Errors.UserAlreadyAskedForInviteException) {
                        this.setState({ signUpEmailError: 'This user has already asked for an invite.' });
                        return;
                    } else if (err.response.data.response === Errors.UserAlreadyInvitedException) {
                        this.setState({ signUpEmailError: 'An invitation has already been sent to this user.' });
                        return;
                    }
                }

                this.setState({ signUpEmailError: 'An unknown error has occured.' });
            })
    }

    render(): JSX.Element {
        const { signUpStep, signUpEmail, signUpEmailError } = this.state;
        return (
            <div className="loginScreenContainer">
                <img className="mainBgImg" src={MainFullBgImg} alt="" />
                <div className="loginScreenHeaderContainer">
                    <img className="mementoLogo" src={mementoLogo} alt="" />
                    <div className="loginScreenMidContainer">
                        <DefaultText className="loginScreenMidContainerText" text="How It Works" onClick={(): void => { this.goToHeader('how-it-works') }} />
                        {/* <DefaultText className="loginScreenMidContainerText" text="Pricing" /> */}
                        <DefaultText className="loginScreenMidContainerText" text="Contact" onClick={(): void => { this.goToHeader('contact') }} />
                    </div>
                    <DefaultButton
                        className="loginScreenLoginButton"
                        textClassName="loginScreenLoginButtonText"
                        text="Log In"
                        onClick={this.onGoogleSignIn}
                    />
                </div>
                <div className="loginScreenInfoContainer">
                    <div className="loginScreenInfoContainerLeft">
                        <DefaultText className="loginScreenWelcomeHeader" text="Save your memories" bold />
                        <DefaultText 
                            className="loginScreenWelcomeBlurb" 
                            text={"Memento automatically categorizes and organizes your links, images, and videos so you can keep exploring without forgetting."} 
                            medium
                        />
                        <div className="loginScreenStepContainer">
                            {signUpStep === 0 && (
                                <React.Fragment>
                                    <DefaultButton 
                                        className="loginScreenGetStartedButton" 
                                        textClassName="loginScreenGetStartedButtonText"
                                        text="Request an invite" 
                                        onClick={(): void => { this.setState({ signUpStep: 1 }); }} 
                                    />
                                    <DefaultText className="loginScreenWelcomeExtraFooter" text="*We're currently only accepting invited users." />
                                </React.Fragment>
                            )}
                            {signUpStep === 1 && (
                                <React.Fragment>
                                    <div className="signUpEmailContainer">
                                        <DefaultTextInput 
                                            className="signUpEmailInput"
                                            value={signUpEmail} 
                                            onValueChange={this.onEmailInput} 
                                            disabledCharCodes={[32]}
                                            placeholder={"Google email"}
                                        />
                                        <DefaultButton
                                            className="signUpEmailRequestButton"
                                            text="Request invite"
                                            onClick={this.onRequestInvite}
                                            disabled={!signUpEmail}
                                        />
                                    </div>
                                    <DefaultText className="signUpEmailInputError" text={signUpEmailError} />
                                    <DefaultText className="loginScreenWelcomeExtraFooter" text="*We're currently only allowing users to sign in with Google." />
                                </React.Fragment>
                            )}
                            {signUpStep === 2 && (
                                <DefaultText className="loginScreenWelcomeExtraFooter" text="Thank you for your interest! We will send you an invite shortly." />
                            )}
                        </div>
                    </div>
                    <div className="loginScreenFaceIllustrationContainer">
                        <Blob 
                            className="loginScreenBlob loginScreenBlob1" 
                            width={500} 
                            height={500} 
                            noiseOffset={30} 
                            canvasWidth={650}
                            canvasHeight={650}
                            color={blob1Color}
                        />
                        <Blob 
                            className="loginScreenBlob loginScreenBlob2" 
                            width={500} 
                            height={500} 
                            noiseOffset={30} 
                            canvasWidth={650}
                            canvasHeight={650}
                            color={blob2Color}
                        />
                        <img className="loginScreenFaceIllustration" src={FaceIllustration} alt="" />
                    </div>
                </div>
                <div className="loginScreenSectionContainer loginScreenPreviewImgContainer">
                    <Blob 
                        className="loginScreenPreviewBlob1" 
                        width={200} 
                        height={200} 
                        noiseOffset={10} 
                        canvasWidth={650}
                        canvasHeight={650}
                        color={blob3Color}
                    />
                    <Blob 
                        className="loginScreenPreviewBlob2" 
                        width={200} 
                        height={200} 
                        noiseOffset={10} 
                        canvasWidth={650}
                        canvasHeight={650}
                        color={blob4Color}
                    />
                    <img className="loginScreenPreviewImg" src={PreviewImg} alt="" />
                </div>
                <div className="loginScreenSectionContainer">
                    <div className="loginScreenSectionHeaderContainer">
                        <Scroll.Element name="how-it-works">
                            <DefaultText className="loginScreenSectionHeaderText" text="How It Works" bold />
                        </Scroll.Element>
                        <DefaultText className="loginScreenSectionHeaderBlurb" text="Input a memory. Memento takes care of the rest." bold />
                    </div>
                    <div className="loginScreenHowItWorksGrid">
                        <div className="loginScreenHowItWorksGridElement">
                            <DefaultText className="loginScreenHowItWorksGridHeader" text="1. Input your memory" bold />
                            <DefaultText className="loginScreenHowItWorksGridBlurb" text="Paste a website URL or a local image from your device and add any additional tags you want to associate the memory with." />
                        </div>
                        <div className="loginScreenHowItWorksGridElement">
                            <DefaultText className="loginScreenHowItWorksGridHeader" text="2. Memento categorizes and tags it" bold />
                            <DefaultText className="loginScreenHowItWorksGridBlurb" text="Using powerful APIs, Memento automatically recognizes key tags as well as categorizes the memory. No more need to create bookmark folders and organize information yourself!" />
                        </div>
                        <div className="loginScreenHowItWorksGridElement">
                            <DefaultText className="loginScreenHowItWorksGridHeader" text="3. Easily search for your memories" bold />
                            <DefaultText className="loginScreenHowItWorksGridBlurb" text="Trying to remember something you saved last month or last year? No worries! Search memories easily by date, categories, or keywords. These keywords don't necessarily need to be the tags you defined!" />
                        </div>
                        <div className="loginScreenHowItWorksGridElement">
                            <DefaultText className="loginScreenHowItWorksGridHeader" text="4. Explore recommendations based on your memories (In development)" bold />
                            <DefaultText className="loginScreenHowItWorksGridBlurb" text="Based on your existing memories, Memento finds new articles, videos, and images for you to explore. " />
                        </div>
                    </div>
                </div>
                <div className="loginScreenSectionContainer">
                    <div className="loginScreenSectionHeaderContainer">
                        <Scroll.Element name="contact">
                            <DefaultText className="loginScreenSectionHeaderText" text="Contact" bold />
                        </Scroll.Element>
                        <DefaultText className="loginScreenSectionHeaderBlurb" text="Get in touch with us." bold />
                        <DefaultText className="loginScreenEmailBlurb" text="Send any questions or feedback to memento.webapp@gmail.com." />
                    </div>
                </div>
            </div>
        )
    }
}
  
export default LoginScreen;
  