// === React Imports ===
import React from 'react';

// === Custom Imports ===
import DefaultText from '../components/default/DefaultText';
import DefaultButton from '../components/default/DefaultButton';
import MainFullBgImg from '../assets/images/main-full-bg-01.png';
import { Routes } from '../utils/routes';
import '../styles/screens/UnauthorizedScreen.scss';

const UnauthorizedScreen = (): JSX.Element => (
    <div>
        <img className="mainBgImg" src={MainFullBgImg} alt="" />
        <div className="unauthorizedScreenContainer">
            <DefaultText className="unauthorizedScreenContainerHeader" text="Oops! It seems you haven't been invited yet." bold />
            <DefaultText className="unauthorizedScreenContainerBlurb" text="We are currently only accepting users who get invited by current users. Do not worry, Memento will be fully open soon!" />
            <DefaultButton className="unauthorizedScreenContainerButton" text="Return to home" onClick={(): void => { window.location.href = `${window.location.origin}${Routes.HOME}` }} />
        </div>
    </div>
);

export default UnauthorizedScreen;
