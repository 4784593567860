// === React Imports ===
import React from 'react';
import { CgNotes } from "react-icons/cg";
import { RiChromeLine, RiAdminLine, RiFeedbackLine } from "react-icons/ri";
import { HiOutlineUserAdd } from "react-icons/hi";

// === Custom Imports ===\
import Api from '../../utils/api';
import DefaultText from '../default/DefaultText';
import mementoLogo from '../../assets/images/memento-logo.png';
import logoutImg from '../../assets/buttons/log-out.svg';
import { openInNewTab } from '../../utils/helpers';
import { Routes } from '../../utils/routes';
import '../../styles/components/default/DefaultNavBar.scss';

type DefaultNavBarProps = {
    onOpenInviteUserModal: () => void;
    onOpenChromeExtensionTokenModal: () => void;
    onOpenFeedbackModal: () => void;
};
type DefaultNavBarState = {
    showMenu: boolean;
    profileImgLoaded: boolean;
};

class DefaultNavBar extends React.Component<DefaultNavBarProps, DefaultNavBarState> {
    dropdownMenu: any;

    constructor(props: DefaultNavBarProps) {
        super(props);
        this.state = {
            showMenu: false,
            profileImgLoaded: false
        }
    }

    componentDidMount(): void {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (e: KeyboardEvent): void => {
        const { showMenu } = this.state;
        if (!showMenu) { return; }

        switch (e.key) {
        case 'Escape':
            this.setState({ showMenu: false });
            break;
        default:
            break;
        }
    }

    showMenu = (event: React.MouseEvent<HTMLImageElement, MouseEvent>): void => {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('mousedown', this.closeMenu);
        });
    }
      
    closeMenu = (event: MouseEvent): void => {
        if (!this.dropdownMenu.contains(event.target)) {
            this.setState({ showMenu: false }, () => {
                document.removeEventListener('mousedown', this.closeMenu);
            });
        }
    }

    onOpenPatchNotes = (): void => {
        openInNewTab('https://www.notion.so/Patch-Notes-96f99fcf87874343be1cfe6cbdf3ccf2')
    }

    logout = (): void => {
        document.cookie = 'memento-jwt=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        localStorage.removeItem('memento-jwt')
        sessionStorage.removeItem('memento-jwt')
        window.location.reload();
    }

    getFirstLetterOfUserName = (): string => {
        if (Api.loggedInUser && Api.loggedInUser.given_name) {
            return Api.loggedInUser.given_name[0];
        }
        
        if (Api.loggedInUser && Api.loggedInUser.family_name) {
            return Api.loggedInUser.family_name[0];
        }

        return ''
    }

    render(): JSX.Element {
        const { onOpenInviteUserModal, onOpenChromeExtensionTokenModal, onOpenFeedbackModal } = this.props;
        const { showMenu, profileImgLoaded } = this.state;
        return (
            <div className="defaultNavBarContainer">
                <img className="mementoLogo" src={mementoLogo} alt="" />
                <div className="defaultNavBarRightContainer">
                    <div className="defaultNavBarProfileImgContainer">
                        <div className="defaultNavBarProfileImgIconContainer">
                            {!profileImgLoaded && (
                                <div className="defaultNavBarProfileImg defaultNavBarProfileNoImg" onClick={this.showMenu}>
                                    <DefaultText text={this.getFirstLetterOfUserName()} />
                                </div>
                            )}
                            <img 
                                className="defaultNavBarProfileImg" 
                                src={Api.loggedInUser.profile_image} 
                                alt=""
                                onClick={this.showMenu} 
                                onLoad={(): void => { this.setState({ profileImgLoaded: true }) }}
                                style={{ opacity: profileImgLoaded ? 1 : 0 }}
                            />
                        </div>
                        {showMenu && (
                            <div className="defaultNavBarProfileMenu" ref={(element) => { this.dropdownMenu = element; }}>
                                {Api.loggedInUser.email === 'andresfernandokim@gmail.com' && (
                                    <React.Fragment>
                                        <div className="defaultNavBarMenuItem" onClick={(): void => { 
                                            this.setState({ showMenu: false }, () => { 
                                                document.removeEventListener('mousedown', this.closeMenu);
                                                window.location.assign(Routes.ADMIN);
                                            });
                                        }}>
                                            <RiAdminLine className="defaultNavBarMenuIcon" />
                                            <DefaultText className="defaultNavBarMenuText" text="Admin Page" />
                                        </div>
                                        <div className="defaultNavBarMenuItem" onClick={(): void => { 
                                            this.setState({ showMenu: false }, () => { 
                                                document.removeEventListener('mousedown', this.closeMenu);
                                                onOpenInviteUserModal() 
                                            });
                                        }}>
                                            <HiOutlineUserAdd className="defaultNavBarMenuIcon" />
                                            <DefaultText className="defaultNavBarMenuText" text="Invite users" />
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="defaultNavBarMenuItem" onClick={this.onOpenPatchNotes}>
                                    <CgNotes className="defaultNavBarMenuIcon" />
                                    <DefaultText className="defaultNavBarMenuText" text="Patch Notes" />
                                </div>
                                <div 
                                    className="defaultNavBarMenuItem" 
                                    onClick={(): void => { 
                                        this.setState({ showMenu: false }, () => { 
                                            document.removeEventListener('mousedown', this.closeMenu);
                                            onOpenChromeExtensionTokenModal() 
                                        });
                                    }}
                                >
                                    <RiChromeLine className="defaultNavBarMenuIcon" />
                                    <DefaultText className="defaultNavBarMenuText" text="Memento Chrome Extension" />
                                </div>
                                <div 
                                    className="defaultNavBarMenuItem" 
                                    onClick={(): void => { 
                                        this.setState({ showMenu: false }, () => { 
                                            document.removeEventListener('mousedown', this.closeMenu);
                                            onOpenFeedbackModal() 
                                        });
                                    }}
                                >
                                    <RiFeedbackLine className="defaultNavBarMenuIcon" />
                                    <DefaultText className="defaultNavBarMenuText" text="Send Feedback" />
                                </div>
                                <div className="defaultNavBarMenuItem" onClick={this.logout}>
                                    <img className="defaultNavBarMenuIcon" src={logoutImg} alt="" />
                                    <DefaultText className="defaultNavBarMenuText" text="Logout" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default DefaultNavBar;
