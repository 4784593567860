// === React Imports ===
import React from 'react';

// === Custom Imports ===
import DefaultText from './DefaultText';
import '../../styles/components/default/DefaultTextInput.scss';

type DefaultTextInputProps = {
  inputRef?: (ref: any) => void;
  placeholder?: string;
  className?: string;
  style?: any;
  type?: any;
  spellCheck?: boolean;
  value: string | number | boolean;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  disabledCharCodes?: Array<number>;
  onEnter?: () => void;
  maxLength?: number;
  onBlur?: () => void;
  onFocus?: () => void;
  searchResults?: Array<string>;
}

type DefaultTextInputState = {
  focused: boolean;
}

class DefaultTextInput extends React.Component<DefaultTextInputProps, DefaultTextInputState> {
    constructor(props: DefaultTextInputProps) {
        super(props);
        this.state = {
            focused: false
        }
    }

    render(): JSX.Element {
        const {
            inputRef,
            placeholder,
            className,
            style,
            type,
            spellCheck,
            value,
            onValueChange,
            disabled,
            disabledCharCodes,
            onEnter,
            maxLength,
            onBlur,
            onFocus,
            searchResults
        } = this.props;

        const showSearchResults = searchResults && searchResults.length > 0;

        return (
            <div className={`defaultInputContainer ${className}`}>
                <input
                    ref={inputRef}
                    className={`defaultTextInput ${showSearchResults && 'defaultTextInputWithResults'} ${disabled && 'defaultTextInputDisabled'}`}
                    style={style}
                    value={`${value}`}
                    type={type || 'text'}
                    placeholder={placeholder}
                    spellCheck={spellCheck}
                    onChange={(e: any): void => { onValueChange(e.target.value) }}
                    autoComplete="new-password"
                    disabled={disabled}
                    maxLength={maxLength}
                    onBlur={(): void => {
                        this.setState({ focused: false });
                        onBlur && onBlur();
                    }}
                    onFocus={(): void => {
                        this.setState({ focused: true });
                        onFocus && onFocus();
                    }}
                    onKeyPress={(e: any) => { 
                        if (e.charCode === 13) {
                            onEnter && onEnter();
                            return true;
                        }

                        if (disabledCharCodes && disabledCharCodes.includes(e.charCode)) {
                            e.preventDefault();
                            return false;
                        }

                        return true;
                    }}
                />
                {showSearchResults && (
                    <div className="defaultInputSearchResults">
                        {searchResults?.map((sr, idx) => (
                            <div key={`input-result-${idx}`} className="defaultInputSearchResult" onMouseDown={(): void => { onValueChange(sr); }}>
                                <DefaultText className="defaultInputSearchResultText" text={sr} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}

export default DefaultTextInput;
