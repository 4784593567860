import React from 'react';
import * as EmailValidator from 'email-validator';
import DefaultText from '../default/DefaultText';
import DefaultTextInput from '../default/DefaultTextInput';
import DefaultButton from '../default/DefaultButton';
import Api from '../../utils/api';
import { Errors } from '../../utils/errors';
import '../../styles/components/modals/CreateMemoryModal.scss';
import '../../styles/components/modals/InviteUserModal.scss';

type InviteUserModalProps = {
    onClose: () => void;
};

type InviteUserModalState = {
    inviteEmail: string;
    error: string;
};

class InviteUserModal extends React.Component<InviteUserModalProps, InviteUserModalState> {
    constructor(props: InviteUserModalProps) {
        super(props);
        this.state = {
            inviteEmail: '',
            error: ''
        }
    }

    onEmailInput = (email: string): void => {
        this.setState({ inviteEmail: email, error: '' });
    }

    onInviteUser = (): void => {
        const { inviteEmail } = this.state;
        Api.inviteUser(inviteEmail)
            .then(() => {
                this.setState({ inviteEmail: '', error: 'User has been succesfully invited.' })
            })
            .catch((err) => {
                console.log(err);
                if (err.response && err.response.status && err.response.data && err.response.status === 400) {
                    if (err.response.data.response === Errors.InvalidEmailSyntaxException) {
                        this.setState({ error: 'Please provide a valid email.' });
                    } else if (err.response.data.response === Errors.UserAlreadyInvitedException) {
                        this.setState({ error: 'This user has already been invited.' });
                    } else if (err.response.data.response === Errors.MaxNumInvitesException) {
                        this.setState({ error: 'You have used all your invites.' });
                    } else if (err.response.data.response === Errors.InviteSelfException) {
                        this.setState({ error: 'You cannot invite yourself.' });
                    }
                }
            })
    }

    render(): JSX.Element {
        const { onClose } = this.props;
        const { inviteEmail, error } = this.state;

        return (
            <div>
                <div className="createMemoryModalBackground" onClick={onClose} />
                <div className="createMemoryModalContainer">
                    <div className="createMemoryModalContentContainer">
                        <DefaultText className="inviteUserModalHeader" text={`Invite User (Send Email)`} bold />
                        <DefaultTextInput
                            className="inviteUserModalInviteInput"
                            value={inviteEmail}
                            onValueChange={this.onEmailInput}
                            placeholder={'Email'}
                        />
                        <DefaultText className="inviteUserModalError" text={error} />
                        <div className="createMemoryModalActionButtonContainer">
                            <DefaultButton 
                                className="inviteUserModalInviteButton" 
                                text="Cancel" 
                                onClick={onClose}
                            />
                            <DefaultButton 
                                className="inviteUserModalInviteButton" 
                                text="Invite" 
                                disabled={!EmailValidator.validate(inviteEmail)} 
                                onClick={this.onInviteUser}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InviteUserModal;
  