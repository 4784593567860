import React from 'react';
import { BsLink, BsTrash2Fill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import DefaultText from '../default/DefaultText';
import DefaultImage from '../default/DefaultImage';
import DefaultTextInput from '../default/DefaultTextInput';
import DefaultButton from '../default/DefaultButton';
import YoutubeVideo from '../YoutubeVideo';
import AddTag from '../AddTag';
import SelectTopic from '../SelectTopic';
import Loader from '../Loader';
import Api from '../../utils/api';
import { Memory } from '../../utils/types';
import { openInNewTab, getBaseURL, isYoutubeVideoURL, getRandomCardBgImg } from '../../utils/helpers';
import { MAX_NUM_TAGS, MAX_TITLE_LENGTH, MAX_TAG_LENGTH, MemoryType } from '../../utils/constants';
import '../../styles/components/modals/CreateMemoryModal.scss';
import '../../styles/components/modals/ViewMemoryModal.scss';

type ViewMemoryModalProps = {
    memoryId: number;
    onClose: () => void;
    onUpdateMemory: (memoryId: number) => void;
    onDeleteMemory: (memoryId: number) => void;
};

type ViewMemoryModalState = {
    memory: Memory | undefined;
    newTag: string;
    showHiddenTags: boolean;
    topicError: string;
    allTopics: Array<string>;
    topicInputFocused: boolean;
    editTopic: boolean;
};

let MAX_TOPIC_LENGTH = 200;

class ViewMemoryModal extends React.Component<ViewMemoryModalProps, ViewMemoryModalState> {
    constructor(props: ViewMemoryModalProps) {
        super(props);
        this.state = {
            memory: undefined,
            newTag: '',
            showHiddenTags: false,
            topicError: '',
            allTopics: [],
            topicInputFocused: false,
            editTopic: false
        }
    }

    componentDidMount(): void {
        this.getAllTopics();
        this.getMemory();
    }

    getAllTopics = (): void => {
        Api.getAllTopics()
            .then((res) => { 
                this.setState({ allTopics: res.data.topics }); 
                for (const t of res.data.topics) {
                    if (t.length > MAX_TOPIC_LENGTH) MAX_TOPIC_LENGTH = t.length;
                }
            })
            .catch((err) => { console.log(err); })
    }

    getMemory = (): void => {
        const { memoryId } = this.props;
        Api.getMemory(memoryId)
            .then((res) => {
                this.setState({ 
                    memory: { 
                        ...res.data.memory, 
                        tags: res.data.memory.tags && res.data.memory.tags.length > 0 ? res.data.memory.tags.split(',') : [],
                        hiddenTags: res.data.memory.hiddenTags && res.data.memory.hiddenTags.length > 0 ? res.data.memory.hiddenTags.split(',') : [],
                        cardBgImg: getRandomCardBgImg()
                    } 
                }, () => {
                    const { memory } = this.state;
                    const updatedMemory = memory;
                    if (updatedMemory && updatedMemory.type === MemoryType.Image) {
                        Api.getFile(updatedMemory.img, {
                            responseType: 'blob'
                        }).then((response) => {
                            const url = window.URL.createObjectURL(response.data);
                            updatedMemory.imgBlobURL = url;
                            this.setState({ memory: updatedMemory });
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                });
            })
    }

    onChangeTitle = (title: string): void => {
        const { memory } = this.state;
        if (!memory) return;
        this.setState({ memory: { ...memory, ...{ title } } });
    }

    onAddTag = (tag: string): void => {
        const { memory } = this.state;
        if (!memory) return;

        const newTags = memory.tags;
        if (newTags.includes(tag)) return;
        newTags.push(tag);
        this.setState({ memory: { ...memory, ...{ tags: newTags } } });
    }

    onRemoveTag = (tag: string): void => {
        const { memory } = this.state;
        if (!memory) return;

        const newTags = memory.tags.filter((t) => t !== tag);
        this.setState({ memory: { ...memory, ...{ tags: newTags } } });
    }

    onNewTag = (value: string): void => {
        this.setState({ newTag: value.replace(/[, ]+/g,'').toLowerCase() });
    }

    onSaveMemory = (): void => {
        const { onClose, onUpdateMemory } = this.props;
        const { memory } = this.state;
        if (!memory) return;

        Api.editMemory(memory)
            .then((res) => {
                onClose();
                onUpdateMemory(res.data.memory.id);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    onDeleteMemory = (): void => {
        const { onClose, onDeleteMemory } = this.props;
        const { memory } = this.state;
        if (!memory) return;

        Api.deleteMemory(memory.id)
            .then(() => {
                onClose();
                onDeleteMemory(memory.id);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    render(): JSX.Element {
        const { onClose } = this.props;
        const { memory, newTag, showHiddenTags, topicError, allTopics, topicInputFocused, editTopic } = this.state;

        let matchedTopics: Array<string> = [];
        if (topicInputFocused) matchedTopics = allTopics;
        if (topicInputFocused && memory && memory.topic) matchedTopics = allTopics.filter((t) => t.toLowerCase().startsWith(memory.topic.toLowerCase()));

        return (
            <div>
                <div className="createMemoryModalBackground" onClick={onClose} />
                <div className="createMemoryModalContainer">
                    {memory === undefined ? (
                        <div className="createMemoryModalLoaderContainer">
                            <Loader />
                        </div>
                    ) : (
                        <div className="createMemoryModalContentContainer">
                            <IoClose className="createMemoryModalClose" onClick={onClose} />
                            <div>
                                {memory.url && (
                                    <div className="createMemoryModalLinkContainer" onClick={(): void => { openInNewTab(`${memory.url}`) }}>
                                        <BsLink className="createMemoryModalLinkImg" />
                                        <DefaultText className="createMemoryModalLink" text={getBaseURL(`${memory.url}`)} />
                                    </div>
                                )}
                                {memory.img && (
                                    <div className="createMemoryModalImgContainer">
                                        <DefaultImage
                                            className="createMemoryModalImg"
                                            src={memory.publicImg ? memory.img : memory.imgBlobURL}
                                        />
                                    </div>
                                )}
                                <input
                                    className="createMemoryModalTitleInput"
                                    value={memory.title}
                                    onChange={(e: any) => {
                                        this.onChangeTitle(e.target.value);
                                    }}
                                    spellCheck={false}
                                    placeholder={'Memory title'}
                                    maxLength={MAX_TITLE_LENGTH}
                                />
                                <div style={{ marginTop: 10 }} />
                                {editTopic ? (
                                    <React.Fragment>
                                        <div className="createMemoryModalTopicInputContainer">
                                            <DefaultTextInput
                                                className="createMemoryModalTagInput"
                                                value={memory.topic}
                                                onValueChange={(text: string): void => { this.setState({ memory: { ...memory, topic: text } }); }}
                                                maxLength={MAX_TOPIC_LENGTH}
                                                searchResults={matchedTopics}
                                                spellCheck={false}
                                                onFocus={() => {
                                                    this.setState({topicInputFocused: true });
                                                }}
                                                onBlur={() => {
                                                    this.setState({topicInputFocused: false });
                                                }}
                                                placeholder="Custom topic"
                                            />
                                        </div>
                                        <DefaultText className="createMemoryModalTopicInputError" text={topicError} />
                                    </React.Fragment>
                                ) : (
                                    <div className="createMemoryModalTopicsContainer">
                                        <SelectTopic
                                            className="viewMemoryModalSelectedTopic"
                                            topic={{ name: memory.topic, confidence: 0 }}
                                            selected
                                            onSelectTopic={(): void => { this.setState({ editTopic: true })}}
                                            hideConfidence
                                        />
                                    </div>
                                )}
                                {(memory.url && isYoutubeVideoURL(memory.url)) && (
                                    <YoutubeVideo url={memory.url} />
                                )}
                                <div className="createMemoryModalSubheaderContainer">
                                    <DefaultText className="createMemoryModalSubheader" text="Tags" bold />
                                    {memory.hiddenTags.length > 0 && (
                                        <DefaultText 
                                            className="createMemoryModalExtraActionText" 
                                            text={showHiddenTags ? "Hide extra tags" : "View extra tags" }
                                            onClick={(): void => { this.setState({ showHiddenTags: !showHiddenTags }) }}
                                        />
                                    )}
                                </div>
                                <DefaultTextInput
                                    className="createMemoryModalTagInput"
                                    value={newTag}
                                    onValueChange={this.onNewTag}
                                    disabledCharCodes={[32, 44]}
                                    maxLength={MAX_TAG_LENGTH}
                                    onEnter={(): void => {
                                        this.onAddTag(newTag);
                                        this.setState({ newTag: '' });
                                    }}
                                    disabled={memory.tags.length >= MAX_NUM_TAGS}
                                    spellCheck={false}
                                    placeholder="Optional tags"
                                />
                                <div className="createMemoryModalTagContainer">
                                    {memory.tags.map((t, idx) => (
                                        <AddTag
                                            key={`add-tag-${idx}`}
                                            className="createMemoryModalTag"
                                            tag={t}
                                            onRemoveTag={this.onRemoveTag}
                                        />
                                    ))}
                                </div>
                                {memory.hiddenTags.length > 0 && (
                                    showHiddenTags && (
                                        <div className="createMemoryModalTagContainer">
                                            {memory.hiddenTags.map((t, idx) => (
                                                <AddTag
                                                    key={`hidden-tag-${idx}`}
                                                    className="createMemoryModalTag"
                                                    tag={t}
                                                    locked
                                                />
                                            ))}
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="createMemoryModalActionButtonContainer">
                                <DefaultButton
                                    className="viewMemoryModalDeleteButton"
                                    textClassName="viewMemoryModalButtonText"
                                    onClick={this.onDeleteMemory}
                                    text="Delete"
                                />
                                <DefaultButton 
                                    className="createMemoryModalSaveButton" 
                                    textClassName="viewMemoryModalButtonText"
                                    text="Save" 
                                    onClick={this.onSaveMemory}
                                    disabled={!memory.topic || !memory.title} 
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default ViewMemoryModal;
  