import React from 'react';
import { BsImageFill } from "react-icons/bs";
import DefaultText from '../components/default/DefaultText';
import DefaultLink from '../components/default/DefaultLink';
import DefaultTextInput from '../components/default/DefaultTextInput';
import DefaultNavBar from '../components/default/DefaultNavBar';
import CreateMemoryModal from '../components/modals/CreateMemoryModal';
import ViewMemoryModal from '../components/modals/ViewMemoryModal';
import InviteUserModal from '../components/modals/InviteUserModal';
import ChromeExtensionTokenModal from '../components/modals/ChromeExtensionTokenModal';
import FeedbackModal from '../components/modals/FeedbackModal';
import ImportChromeBookmarksModal from '../components/modals/ImportChromeBookmarksModal';
import Blob from '../components/svg/Blob';
import MemoryGrid from '../views/MemoryGrid';
import Api from '../utils/api';
import { MemoryType, MemoryGridViewType } from '../utils/constants';
import { Memory } from '../utils/types';
import { getRandomCardBgImg, openInNewTab } from '../utils/helpers';
import { BlobColors } from '../utils/constants';
import '../styles/screens/HomeScreen.scss';
import DefaultButton from '../components/default/DefaultButton';

type HomeScreenProps = any;
type HomeScreenState = {
    memoryURL: string;
    memoryImg: any;
    newMemoryURLError: boolean;
    showCreateMemoryModal: boolean;
    showViewMemoryModal: boolean;
    showInviteUserModal: boolean;
    showChromeExtensionTokenModal: boolean;
    showFeedbackModal: boolean;
    selectedMemory: Memory | undefined;
    memoryGridViewType: string;
    memories: Array<Memory>;
    loadingMemories: boolean;
    totalNumMemories: number;
    memoriesPage: number;
    memoriesLimit: number;
    searchMemory: string;
    isMobile: boolean;
    initialMemoriesExisted: boolean;
};

const randomBlobColors = [
    BlobColors[Math.floor(Math.random() * BlobColors.length)],
    BlobColors[Math.floor(Math.random() * BlobColors.length)],
    BlobColors[Math.floor(Math.random() * BlobColors.length)],
];

class HomeScreen extends React.Component<HomeScreenProps, HomeScreenState> {
    urlInputRef: any;
    fileInputRef: any;

    constructor(props: HomeScreenProps) {
        super(props);
        this.state = {
            memoryURL: '',
            memoryImg: undefined,
            newMemoryURLError: false,
            showCreateMemoryModal: false,
            showViewMemoryModal: false,
            showInviteUserModal: false,
            showChromeExtensionTokenModal: false,
            showFeedbackModal: false,
            selectedMemory: undefined,
            memoryGridViewType: MemoryGridViewType.Date,
            memories: [],
            loadingMemories: true,
            totalNumMemories: 0,
            memoriesPage: 0,
            memoriesLimit: 50,
            searchMemory: '',
            isMobile: false,
            initialMemoriesExisted: false
        }
    }

    componentDidMount(): void {
        this.updateIsMobile();
        window.addEventListener("resize", this.updateIsMobile);
        document.addEventListener("keydown", this.handleKeyDown, false);
        this.getMemories(true, false);
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleKeyDown, false);
        window.removeEventListener("resize", this.updateIsMobile, false);
    }

    updateIsMobile = (): void => {
        this.setState({ isMobile: window.innerWidth <= 700 });
    }

    handleKeyDown = (event: KeyboardEvent): void => {
        if (event.keyCode === 27) {
            this.setState({ showCreateMemoryModal: false, showViewMemoryModal: false });
        }
    }

    getMemories = (initial: boolean, loadMore: boolean, reset?: boolean): void => {
        const { memoriesPage, memoriesLimit, memories, memoryGridViewType, searchMemory, initialMemoriesExisted } = this.state;

        const prevImgBlobs: { [key: string]: string } = {};
        const prevCardBgImgs : { [key: string]: string } = {};
        for (let i = 0; i < memories.length; i++) {
            if (memories[i].type === MemoryType.Image && memories[i].imgBlobURL) {
                prevImgBlobs[memories[i].id] = memories[i].imgBlobURL
            }

            prevCardBgImgs[memories[i].id] = memories[i].cardBgImg;
        }

        (searchMemory ? Api.searchMemory(searchMemory, memoriesPage, memoriesLimit) : Api.getMemories(memoriesPage, memoriesLimit, memoryGridViewType))
            .then((res) => {
                const newMemories = res.data.memories;
                for (let i = 0; i < newMemories.length; i++) {
                    if (prevImgBlobs[newMemories[i].id]) {
                        newMemories[i].imgBlobURL = prevImgBlobs[newMemories[i].id];
                    }

                    if (prevCardBgImgs[newMemories[i].id]) {
                        newMemories[i].cardBgImg = prevCardBgImgs[newMemories[i].id];
                    } else {
                        newMemories[i].cardBgImg = getRandomCardBgImg();
                    }
                }
                
                const addToExistingMemories = ((!searchMemory || loadMore) && !reset);
                this.setState({ 
                    memories: addToExistingMemories ? memories.concat(newMemories) : newMemories,
                    totalNumMemories: res.data.totalNumMemories,
                    loadingMemories: false,
                    initialMemoriesExisted: initial ? newMemories.length > 0 : initialMemoriesExisted
                }, () => {
                    for (let i = 0; i < newMemories.length; i++) {
                        // Get any images
                        if (newMemories[i].type === MemoryType.Image && !newMemories[i].imgBlobURL) {
                            Api.getFile(newMemories[i].img, {
                                responseType: 'blob'
                            }).then((response) => {
                                const url = window.URL.createObjectURL(response.data);
                                const updatedMemories = this.state.memories;
                                for (const mem of updatedMemories) {
                                    if (mem.id === newMemories[i].id) {
                                        mem.imgBlobURL = url;
                                        this.setState({ memories: updatedMemories });
                                        return;
                                    }
                                }
                            }).catch((error) => {
                                console.log(error);
                            });
                        }
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    sortMemoriesBy = (sortType: string): void => {
        this.setState({ 
            memoryGridViewType: sortType,
            memoriesPage: 0,
        }, () => {
            this.getMemories(false, false, true);
        });
    }

    reloadMemory = (memoryId: number): void => {
        Api.getMemory(memoryId)
            .then((res) => {
                const { memories } = this.state;
                const newMemory = res.data.memory;
                const newMemories = memories;

                for (let i = 0; i < newMemories.length; i++) {
                    if (newMemories[i].id === memoryId) {
                        if (newMemory.type === MemoryType.Image && newMemories[i].imgBlobURL) {
                            newMemory.imgBlobURL = newMemories[i].imgBlobURL;
                        }

                        newMemory.cardBgImg = newMemories[i].cardBgImg;
                        newMemories[i] = newMemory;
                        this.setState({ memories: newMemories });
                        return;
                    }
                }
            })
    }

    loadMoreMemories = (): void => {
        const { memoriesPage } = this.state;
        this.setState({ memoriesPage: memoriesPage + 1 }, () => {
            this.getMemories(false, true);
        });
    }

    onSelectMemory = (memory: Memory): void => {
        this.setState({ showViewMemoryModal: true, selectedMemory: memory });
    }

    onCloseViewMemory = (): void => {
        this.setState({ showViewMemoryModal: false, selectedMemory: undefined });
    }

    onDeleteMemory = (memoryId: number): void => {
        const { memories, totalNumMemories } = this.state;
        const newMemories = memories.filter((m) => m.id !== memoryId);
        this.setState({ 
            memories: newMemories,
            totalNumMemories: totalNumMemories - 1
        });
    }

    onURLInputChange = (value: string): void => {
        const MAX_URL_LENGTH = 2000;
        this.setState({ 
            memoryURL: value,
            newMemoryURLError: value.length > MAX_URL_LENGTH
        });
    }

    onConfirmNewMemoryURL = (): void => {
        const { memoryURL, newMemoryURLError } = this.state;
        if (!memoryURL || newMemoryURLError) return;

        this.setState({ showCreateMemoryModal: true });
    }

    onCloseNewMemory = (): void => {
        if (this.fileInputRef) { this.fileInputRef.value = ''; }
        this.setState({ showCreateMemoryModal: false, memoryURL: '', memoryImg: undefined });
    }

    onNewMemoryError = (): void => {
        this.setState({ showCreateMemoryModal: false, newMemoryURLError: true }, () => {
            this.urlInputRef && this.urlInputRef.blur();
        });
    }

    createTestMemories = async (): Promise<any> => {
        const memory = { 
            title: 'Hello World Bye Google Zeplin', 
            url: 'https://www.google.com/',
            topic: 'Business Services', 
            tags: ['website', 'media'], 
            type: 'URL' 
        };

        for (let i = 0; i < 100; i++) {
            await Api.createURLMemory(memory.url, `${memory.title} ${i}`, memory.topic, memory.tags.join(','));
        }

        console.log('done creating test memories');
        window.location.reload();
    }

    onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fileList = (event.target.files as FileList);
        if (fileList.length > 0) {
            this.setState({
                memoryURL: '',
                memoryImg: fileList[0],
                showCreateMemoryModal: true
            });
        }
    }

    onSearchMemory = (text: string): void => {
        this.setState({ searchMemory: text, memoriesPage: 0 }, () => {
            this.getMemories(false, false, text === '');
        });
    }

    render(): JSX.Element {
        const { 
            showCreateMemoryModal, 
            showViewMemoryModal,
            showChromeExtensionTokenModal,
            showFeedbackModal,
            memoryURL,
            memoryImg,
            newMemoryURLError, 
            memoryGridViewType, 
            memories,
            loadingMemories,
            selectedMemory,
            totalNumMemories,
            searchMemory,
            isMobile,
            showInviteUserModal,
            initialMemoriesExisted
        } = this.state;
        const noMemoriesYet = (memories.length === 0 && !loadingMemories && !initialMemoriesExisted && !searchMemory);

        if (loadingMemories) return <div />;

        const addMemoryInput = (
            <div className={`homeScreenAddMemoryInputContainer ${noMemoriesYet && 'homeScreenAddMemoryInputContainerNoMemories'} ${isMobile ? 'homeScreenAddMemoryInputContainerMobile' : 'homeScreenAddMemoryInputContainerDesktop' }`}>
                <DefaultTextInput
                    inputRef={(ref: any) => this.urlInputRef = ref}
                    className="homeScreenAddMemoryInput homeScreenAddMemoryInputDesktop"
                    style={{
                        ...newMemoryURLError ? { borderColor: '#eb2f39' } : {}
                    }}
                    value={memoryURL}
                    onValueChange={this.onURLInputChange}
                    spellCheck={false}
                    placeholder="Save a memory (url)"
                    disabledCharCodes={[32]}
                    onEnter={this.onConfirmNewMemoryURL}
                />
                {noMemoriesYet && (
                    <DefaultButton
                        className="noMemoriesExampleButton"
                        text="Example URL"
                        onClick={(): void => {
                            this.setState({ memoryURL: 'https://www.youtube.com/watch?v=7ijMDQgvW0o&ab_channel=Funkissman78' }, () => {
                                this.onConfirmNewMemoryURL();
                            });
                        }}
                    />
                )}
                <div className="homeScreenFileInputContainer">
                    <input 
                        ref={(ref: any) => this.fileInputRef = ref}
                        className="homeScreenFileInput"
                        id="file" 
                        type="file" 
                        name="file" 
                        onChange={this.onFileChange} 
                        accept="image/x-png,image/gif,image/jpeg"
                    />
                    <label htmlFor="file">
                        <BsImageFill className="homeScreenAddImage" />
                    </label>
                </div>
            </div>
        )

        return (
            <div className="homeScreenContainer">
                {/* <button onClick={(): void => { this.createTestMemories() }}>Create test memories</button> */}
                <DefaultNavBar 
                    onOpenInviteUserModal={(): void => { this.setState({ showInviteUserModal: true }) }} 
                    onOpenChromeExtensionTokenModal={(): void => { this.setState({ showChromeExtensionTokenModal: true }) }}
                    onOpenFeedbackModal={(): void => { this.setState({ showFeedbackModal: true }) }}
                />
                {noMemoriesYet && (
                    <div className="noMemoriesInfoContainer">
                        <div className="noMemoriesBlobContainer">
                            {randomBlobColors.map((b, idx) => (
                                <Blob
                                    key={`blob-${idx}`}
                                    className="noMemoriesBlob" 
                                    width={400} 
                                    height={400} 
                                    noiseOffset={25} 
                                    canvasWidth={400}
                                    canvasHeight={400}
                                    color={b}
                                />
                            ))}
                        </div>
                        <DefaultText className="noMemoriesInfoText" text="Save your first memory." bold />
                        <DefaultText className="noMemoriesInfoBlurb" text="Found an interesting article or picture? Store it here so you don't forget about it!" />
                    </div>
                )}
                {isMobile && addMemoryInput}
                <div className="homeScreenContentHeaderContainer">
                    {!noMemoriesYet && (
                        <div className="homeScreenSortContainer">
                            {Object.keys(MemoryGridViewType).map((s, idx) => (
                                <DefaultLink
                                    key={`memory-sort-${idx}`}
                                    className="homeScreenSortText" 
                                    text={s} 
                                    selected={memoryGridViewType === s} 
                                    onClick={(): void => {  this.sortMemoriesBy(s) }} 
                                />
                            ))}
                        </div>
                    )}
                    {!isMobile && addMemoryInput}
                    {!noMemoriesYet && (
                        <DefaultTextInput
                            value={searchMemory}
                            onValueChange={this.onSearchMemory}
                            placeholder={'Search memory'}
                            spellCheck={false}
                        />
                    )}
                </div>
                {!noMemoriesYet && (
                    <MemoryGrid 
                        sortMemoriesBy={memoryGridViewType}
                        memories={memories}
                        loadMoreMemories={this.loadMoreMemories}
                        totalNumMemories={totalNumMemories}
                        onSelectMemory={this.onSelectMemory}
                        isSearchResult={searchMemory !== ''}
                    />
                )}
                {noMemoriesYet && (
                    <DefaultText
                        className="homeScreenUseExtensionText"
                        text="Download the Memento Chrome Extension to save time."
                        onClick={(): void => {
                            openInNewTab('https://chrome.google.com/webstore/detail/memento/djcdckamihmkfclojigjpccfkgliijgo?hl=en');
                        }}
                    />
                )}
                {showCreateMemoryModal && (
                    <CreateMemoryModal
                        onClose={this.onCloseNewMemory}
                        onError={this.onNewMemoryError}
                        memoryURL={memoryURL}
                        memoryImg={memoryImg}
                    />
                )}
                {(showViewMemoryModal && selectedMemory) && (
                    <ViewMemoryModal
                        memoryId={selectedMemory.id}
                        onClose={this.onCloseViewMemory}
                        onUpdateMemory={this.reloadMemory}
                        onDeleteMemory={this.onDeleteMemory}
                    />
                )}
                {showInviteUserModal && (
                    <InviteUserModal
                        onClose={(): void => { this.setState({ showInviteUserModal: false }) }}
                    />
                )}
                {showChromeExtensionTokenModal && (
                    <ChromeExtensionTokenModal
                        onClose={(): void => { this.setState({ showChromeExtensionTokenModal: false }) }}
                    />
                )}
                {showFeedbackModal && (
                    <FeedbackModal
                        onClose={(): void => { this.setState({ showFeedbackModal: false }) }}
                    />
                )}
            </div>
        )
    }
}
  
export default HomeScreen;
  