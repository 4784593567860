import React from 'react';
import { BsLink } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import DefaultText from '../default/DefaultText';
import DefaultImage from '../default/DefaultImage';
import DefaultTextInput from '../default/DefaultTextInput';
import DefaultButton from '../default/DefaultButton';
import SelectTopic from '../SelectTopic';
import AddTag from '../AddTag';
import Loader from '../Loader';
import YoutubeVideo from '../YoutubeVideo';
import Api from '../../utils/api';
import { NewMemory } from '../../utils/types';
import { openInNewTab, getBaseURL, isYoutubeVideoURL, getRandomCardBgImg } from '../../utils/helpers';
import { Errors } from '../../utils/errors';
import { MAX_NUM_TAGS, MAX_TITLE_LENGTH, MAX_TAG_LENGTH } from '../../utils/constants';
import '../../styles/components/modals/CreateMemoryModal.scss';

type CreateModalModalProps = {
    memoryURL: string;
    memoryImg: any;
    onClose: () => void;
    onError: () => void;
};

type CreateModalModalState = {
    newMemory: NewMemory;
    loadingNewMemory: boolean;
    newMemoryError: string;
    allTopics: Array<string>;
    newTag: string;
    newTopic: string;
    topicInputFocused: boolean;
    topicError: string;
    showHiddenTags: boolean;
    showCustomTopicInput: boolean;
};

let MAX_TOPIC_LENGTH = 200;
const MAX_IMG_SIZE_MB = 50;
const NO_TOPIC_MSG = 'Unfortunately, we did not have enough data to retrieve a topic. Please provide a topic.';

class CreateMemoryModal extends React.Component<CreateModalModalProps, CreateModalModalState> {
    constructor(props: CreateModalModalProps) {
        super(props);
        const { memoryURL } = this.props;
        this.state = {
            newMemory: {
                url: memoryURL,
                img: undefined,
                title: '',
                topics: [],
                selectedTopic: '',
                tags: [],
                hiddenTags: [],
                cardBgImg: getRandomCardBgImg()
            },
            loadingNewMemory: true,
            newMemoryError: '',
            allTopics: [],
            newTag: '',
            newTopic: '',
            topicInputFocused: false,
            topicError: '',
            showHiddenTags: false,
            showCustomTopicInput: false
        }
    }

    componentDidMount(): void {
        const { memoryURL, memoryImg } = this.props;
        this.getAllTopics();

        if (memoryURL) {
            this.getURLTopics();
        } else if (memoryImg) {
            this.validateImg();
        }
    }

    validateImg = (): void => {
        const { memoryImg } = this.props;
        const fileSize = (memoryImg.size/1024)/1024; // MB
        if (fileSize >= MAX_IMG_SIZE_MB) {
            this.setState({ 
                loadingNewMemory: false, 
                newMemoryError: `File size must be under ${MAX_IMG_SIZE_MB}MB.` 
            });
        }

        const data = new FormData();
        data.append('file', memoryImg);
        Api.getImageTopicAndLabels(data)
            .then((res) => {
                const { newMemory } = this.state;
                const reader = new FileReader();
                reader.onload = () => {
                    const topics = res.data.topics;
                    this.setState({ 
                        loadingNewMemory: false,
                        newMemory: {
                            ...newMemory,
                            img: reader.result,
                            topics,
                            selectedTopic: topics.length > 0 ? topics[0].name : '',
                            hiddenTags: res.data.hiddenTags,
                        },
                        topicError: topics.length > 0 ? '' : NO_TOPIC_MSG,
                        showCustomTopicInput: topics.length === 0
                    });
                };
                reader.readAsDataURL(memoryImg);
            })
            .catch((err) =>{ 
                console.log(err);
                this.setState({ 
                    topicError: NO_TOPIC_MSG, 
                    showCustomTopicInput: true,
                    loadingNewMemory: false
                });
            })
    }

    getURLTopics = (): void => {
        const { memoryURL, onError } = this.props;
        const { newMemory } = this.state;

        Api.getURLTopicsAndTitle(memoryURL)
            .then((res) => {
                const topics = res.data.topics;
                this.setState({
                    newMemory: { 
                        ...newMemory, 
                        ...{ 
                            title: res.data.title, 
                            topics,
                            selectedTopic: topics.length > 0 ? topics[0].name : '',
                            hiddenTags: res.data.hiddenTags,
                            img: res.data.img
                        }
                    },
                    loadingNewMemory: false,
                    topicError: topics.length > 0 ? '' : NO_TOPIC_MSG,
                    showCustomTopicInput: topics.length === 0
                }); 
            })
            .catch((err) => {
                console.log(err);
                if (err.response && err.response.status && err.response.data && err.response.status === 400) {
                    if (err.response.data.response === Errors.URLNotRetreivableException || err.response.data.response === Errors.UnknownException) {
                        this.setState({ 
                            topicError: NO_TOPIC_MSG, 
                            showCustomTopicInput: true,
                            loadingNewMemory: false
                        });
                    } else if (err.response.data.response === Errors.InvalidURLException) {
                        onError();
                    }
                }
            })
    }

    getAllTopics = (): void => {
        Api.getAllTopics()
            .then((res) => { 
                this.setState({ allTopics: res.data.topics }); 
                for (const t of res.data.topics) {
                    if (t.length > MAX_TOPIC_LENGTH) MAX_TOPIC_LENGTH = t.length;
                }
            })
            .catch((err) => { console.log(err); })
    }

    onChangeTitle = (title: string): void => {
        const { newMemory } = this.state;
        this.setState({ newMemory: { ...newMemory, ...{ title } } });
    }

    onSelectTopic = (topic: string): void => {
        const { newMemory } = this.state;
        this.setState({ newMemory: { ...newMemory, ...{ selectedTopic: topic } } });
    }

    onAddTag = (tag: string): void => {
        if (!tag) return;

        const { newMemory } = this.state;
        const newTags = newMemory.tags;

        if (newTags.includes(tag)) return;
        newTags.push(tag.replace(',', ' '));
        this.setState({ newMemory: { ...newMemory, ...{ tags: newTags } } });
    }

    onRemoveTag = (tag: string): void => {
        const { newMemory } = this.state;
        const newTags = newMemory.tags.filter((t) => t !== tag);
        this.setState({ newMemory: { ...newMemory, ...{ tags: newTags } } });
    }

    onNewTag = (value: string): void => {
        this.setState({ newTag: value.replace(/[, ]+/g,'').toLowerCase() });
    }

    onNewTopic = (value: string): void => {
        this.setState({ newTopic: value, topicError: '' });
        this.onSelectTopic(value);
    }

    onCreateMemory = (): void => {
        const { memoryImg } = this.props;
        const { newMemory } = this.state;

        let apiCall;
        if (memoryImg) {
            const data = new FormData();
            data.append('title', newMemory.title);
            data.append('topic', newMemory.selectedTopic);
            data.append('file', memoryImg);
            if (newMemory.tags) {
                data.append('tags', newMemory.tags.join(','));
            }

            if (newMemory.hiddenTags) {
                data.append('hiddenTags', newMemory.hiddenTags.join(','));
            }

            apiCall = Api.createImageMemory(data);
        } else {
            apiCall = Api.createURLMemory(
                newMemory.url,
                newMemory.title,
                newMemory.selectedTopic,
                newMemory.tags.join(','),
                newMemory.hiddenTags.join(','),
                newMemory.img
            )
        }

        apiCall
            .then(() => {
                this.setState({ loadingNewMemory: true }, () => {
                    setTimeout(() => {
                        window.location.reload(); 
                    }, 2 * 1000);
                })
            })
            .catch((err) => { 
                console.log(err);
            });
    }

    render(): JSX.Element {
        const { onClose } = this.props;
        const {
            loadingNewMemory,
            newMemoryError,
            allTopics,
            newMemory, 
            newTag, 
            newTopic,
            topicInputFocused,
            topicError,
            showHiddenTags,
            showCustomTopicInput
        } = this.state;

        let matchedTopics: Array<string> = [];
        if (topicInputFocused) matchedTopics = allTopics;
        if (topicInputFocused && newTopic) matchedTopics = allTopics.filter((t) => t.toLowerCase().startsWith(newTopic.toLowerCase()));

        return (
            <div>
                <div className="createMemoryModalBackground" onClick={onClose} />
                <div className="createMemoryModalContainer">
                    {loadingNewMemory ? (
                        <div className="createMemoryModalLoaderContainer">
                            <Loader />
                        </div>
                    ) : (
                        <div className="createMemoryModalContentContainer">
                            <IoClose className="createMemoryModalClose" onClick={onClose} />
                            {newMemoryError ? (
                                <div className="createModalErrorContainer">
                                    <DefaultText className="createModalErrorText" text={newMemoryError} />
                                    <DefaultButton className="createMemoryModalCancelButton" text="Back" onClick={onClose} />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div>
                                        {newMemory.url && (
                                            <div className="createMemoryModalLinkContainer" onClick={(): void => { openInNewTab(newMemory.url) }}>
                                                <BsLink className="createMemoryModalLinkImg" />
                                                <DefaultText className="createMemoryModalLink" text={getBaseURL(newMemory.url)} />
                                            </div>
                                        )}
                                        {newMemory.img && (
                                            <div className="createMemoryModalImgContainer">
                                                <DefaultImage
                                                    className="createMemoryModalImg"
                                                    src={newMemory.img}
                                                />
                                            </div>
                                        )}
                                        <input
                                            className="createMemoryModalTitleInput"
                                            value={newMemory.title}
                                            onChange={(e: any) => {
                                                this.onChangeTitle(e.target.value);
                                            }}
                                            spellCheck={false}
                                            placeholder={'Memory title'}
                                            maxLength={MAX_TITLE_LENGTH}
                                        />
                                        <div className="createMemoryModalSubheaderContainer">
                                            <DefaultText className="createMemoryModalSubheader" text="Topic" bold />
                                            {(showCustomTopicInput && newMemory.topics.length > 0) && (
                                                <DefaultText 
                                                    className="createMemoryModalExtraActionText" 
                                                    text="Back to topics"
                                                    onClick={(): void => {
                                                        this.setState({ 
                                                            showCustomTopicInput: false,
                                                            newMemory: { 
                                                                ...newMemory, 
                                                                selectedTopic: newMemory.topics[0].name,
                                                            },
                                                            newTopic: ''
                                                        });
                                                    }}
                                                />
                                            )}
                                            {!showCustomTopicInput && (
                                                <DefaultText 
                                                    className="createMemoryModalExtraActionText" 
                                                    text="Not the right topic?"
                                                    onClick={(): void => {
                                                        this.setState({ 
                                                            showCustomTopicInput: true,
                                                            newMemory: { ...newMemory, selectedTopic: '' } 
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {(newMemory.topics.length > 0 && !showCustomTopicInput) ? (
                                            <div className="createMemoryModalTopicsContainer">
                                                {newMemory.topics.map((t, idx) => (
                                                    <SelectTopic
                                                        key={`select-topic-${idx}`}
                                                        className="createMemoryModalTopic"
                                                        onSelectTopic={this.onSelectTopic}
                                                        topic={t}
                                                        selected={t.name === newMemory.selectedTopic}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <div className="createMemoryModalTopicInputContainer">
                                                    <DefaultTextInput
                                                        className="createMemoryModalTagInput"
                                                        value={newTopic}
                                                        onValueChange={this.onNewTopic}
                                                        maxLength={MAX_TOPIC_LENGTH}
                                                        searchResults={matchedTopics}
                                                        spellCheck={false}
                                                        onFocus={() => {
                                                            this.setState({topicInputFocused: true });
                                                        }}
                                                        onBlur={() => {
                                                            this.setState({topicInputFocused: false });
                                                        }}
                                                        placeholder="Custom topic"
                                                    />
                                                </div>
                                                <DefaultText className="createMemoryModalTopicInputError" text={topicError} />
                                            </React.Fragment>
                                        )}
                                        {isYoutubeVideoURL(newMemory.url) && (
                                            <YoutubeVideo url={newMemory.url} />
                                        )}
                                        <div className="createMemoryModalSubheaderContainer">
                                            <DefaultText className="createMemoryModalSubheader" text="Tags" bold />
                                            {newMemory.hiddenTags.length > 0 && (
                                                <DefaultText 
                                                    className="createMemoryModalExtraActionText" 
                                                    text={showHiddenTags ? "Hide extra tags" : "View extra tags" }
                                                    onClick={(): void => { this.setState({ showHiddenTags: !showHiddenTags }) }}
                                                />
                                            )}
                                        </div>
                                        <DefaultTextInput
                                            className="createMemoryModalTagInput"
                                            value={newTag}
                                            onValueChange={this.onNewTag}
                                            disabledCharCodes={[32, 44]}
                                            maxLength={MAX_TAG_LENGTH}
                                            onEnter={(): void => {
                                                this.onAddTag(newTag);
                                                this.setState({ newTag: '' });
                                            }}
                                            disabled={newMemory.tags.length >= MAX_NUM_TAGS}
                                            spellCheck={false}
                                            placeholder="Optional tags"
                                        />
                                        <div className="createMemoryModalTagContainer">
                                            {newMemory.tags.map((t, idx) => (
                                                <AddTag
                                                    key={`add-tag-${idx}`}
                                                    className="createMemoryModalTag"
                                                    tag={t}
                                                    onRemoveTag={this.onRemoveTag}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    {newMemory.hiddenTags.length > 0 && (
                                        showHiddenTags && (
                                            <div className="createMemoryModalTagContainer">
                                                {newMemory.hiddenTags.map((t, idx) => (
                                                    <AddTag
                                                        key={`hidden-tag-${idx}`}
                                                        className="createMemoryModalTag"
                                                        tag={t}
                                                        locked
                                                    />
                                                ))}
                                            </div>
                                        )
                                    )}
                                    <div className="createMemoryModalActionButtonContainer">
                                        <DefaultButton className="createMemoryModalCancelButton" text="Cancel" onClick={onClose} />
                                        <DefaultButton className="createMemoryModalSaveButton" text="Save" onClick={this.onCreateMemory} disabled={!newMemory.title || !newMemory.selectedTopic} />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default CreateMemoryModal;
  