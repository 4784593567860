import React from "react";
import '../../styles/components/default/DefaultImage.scss';

type DefaultImageProps = {
    src: string;
    className?: string;
    onClick?: () => void;
    alt?: string;
}

type DefaultImageState = {
    loaded: boolean;
}

class DefaultImage extends React.Component<DefaultImageProps, DefaultImageState> {
    constructor(props: DefaultImageProps) {
        super(props);
        this.state = {
            loaded: false
        }
    }

  handleImageLoaded = (): void => {
      this.setState({ loaded: true });
  }

  render(): JSX.Element {
      const { src, className, onClick, alt } = this.props;
      const { loaded } = this.state;
      const imageStyle = !loaded ? { display: "none" } : {};
      return ( 
          <React.Fragment>
              {!loaded && (
                  <div className={`defaultImageLoadingContainer ${className}`} onClick={onClick} />
              )}
              <img className={`defaultImage ${className}`} src={src} style={imageStyle} onLoad={this.handleImageLoaded} alt={alt} onClick={onClick} />
          </React.Fragment>
      )
  }
}

export default DefaultImage;