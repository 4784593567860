import React from 'react';
import DefaultText from '../components/default/DefaultText';
import DefaultTextInput from '../components/default/DefaultTextInput';
import DefaultButton from '../components/default/DefaultButton';
import Api from '../utils/api';
import { Errors } from '../utils/errors';
import { Routes } from '../utils/routes';
import '../styles/screens/AdminScreen.scss';

type AdminScreenProps = any;
type AdminScreenState = {
    customEmailQueryOptions: Array<{ queryName: string; queryValue: string; }>;
    customEmailSubject: string;
    customEmailHTML: string;
    customEmailError: string;
    inviteEmail: string;
    notInvitedEmails: Array<any>;
    inviteNotAcceptedEmails: Array<any>;
    requestEmail: string;
    requestEmailError: string;
}

class AdminScreen extends React.Component<AdminScreenProps, AdminScreenState> {
    constructor(props: AdminScreenProps) {
        super(props);
        this.state = {
            customEmailQueryOptions: [
                { queryName: '', queryValue: '' },
                { queryName: '', queryValue: '' },
                { queryName: '', queryValue: '' },
            ],
            customEmailSubject: '',
            customEmailHTML: '',
            customEmailError: '',
            inviteEmail: '',
            notInvitedEmails: [],
            inviteNotAcceptedEmails: [],
            requestEmail: '',
            requestEmailError: ''
        }
    }

    componentDidMount(): void {
        this.getUsers();
    }

    getUsers = (): void => {
        Api.getNotInvitedUsers()
            .then((res) => {
                this.setState({ notInvitedEmails: res.data.users.map((u: any) => u.email) });
            })
            .catch((err) => {
                console.log(err);
            })

        Api.getInviteNotAcceptedUsers()
            .then((res) => {
                this.setState({ inviteNotAcceptedEmails: res.data.users.map((u: any) => u.email) })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    onAddQuery = (): void => {
        const newCustomEmailQueryOptions = this.state.customEmailQueryOptions;
        newCustomEmailQueryOptions.push({ queryName: '', queryValue: '' });
        this.setState({ customEmailQueryOptions: newCustomEmailQueryOptions });
    }

    sendInvite = (email: string): void => {
        Api.inviteUser(email)
            .then(() => {
                this.getUsers();
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    sendEmail = (): void => {
        const { customEmailQueryOptions, customEmailSubject, customEmailHTML } = this.state;
        const queryOptions: { [key: string]: string } = {};
        for (const q of customEmailQueryOptions) {
            if (q.queryName && q.queryValue) {
                queryOptions[q.queryName] = q.queryValue;
            }
        }

        Api.sendCustomEmail(queryOptions, customEmailSubject, customEmailHTML)
            .then(() => {
                this.setState({
                    customEmailQueryOptions: [
                        { queryName: '', queryValue: '' },
                        { queryName: '', queryValue: '' },
                        { queryName: '', queryValue: '' },
                    ],
                    customEmailSubject: '',
                    customEmailHTML: '',
                    customEmailError: 'Email successfully sent.'
                })
            })
            .catch((err) => {
                console.log(err);
                if (err.response && err.response.status && err.response.data && err.response.status === 400) {
                    if (err.response.data.response === Errors.NoUsersMatchQuery) {
                        this.setState({ customEmailError: 'No users matched the query provided.' });
                    } else if (err.response.data.response === Errors.UnknownException) {
                        this.setState({ customEmailError: 'Unknown Exception. Most likely incorrect query params.' });
                    }
                }
            })
    }

    requestInviteNoEmail = (): void => {
        const { requestEmail } = this.state;
        Api.requestInvite(requestEmail, true)
            .then(() => {
                this.setState({ requestEmail: '', requestEmailError: 'User successfully created without sending email.' });
                this.getUsers();
            })
            .catch((err) => {
                console.log(err);
                if (err.response && err.response.status && err.response.data && err.response.status === 400) {
                    if (err.response.data.response === Errors.InvalidEmailSyntaxException) {
                        this.setState({ requestEmailError: 'Please provide a valid email.' });
                        return;
                    } else if (err.response.data.response === Errors.UserAlreadyAskedForInviteException) {
                        this.setState({ requestEmailError: 'This user has already been created.' });
                        return;
                    } else if (err.response.data.response === Errors.UserAlreadyInvitedException) {
                        this.setState({ requestEmailError: 'An invitation has already been sent to this user.' });
                        return;
                    }
                }

                this.setState({ requestEmailError: 'Unknown Error.' });
            })
    }

    render(): JSX.Element {
        const { 
            customEmailQueryOptions, 
            customEmailSubject, 
            customEmailHTML, 
            customEmailError,
            notInvitedEmails,
            inviteNotAcceptedEmails,
            requestEmail,
            requestEmailError
        } = this.state;

        return (
            <div className="adminScreen">
                <DefaultText 
                    className="adminScreenGoHome"
                    text="Home" 
                    onClick={(): void => { window.location.assign(Routes.HOME); }} 
                />
                <div className="adminContainer">
                    <DefaultText className="adminContainerHeader" text={`Request invite (No Email)`} bold />
                    <DefaultTextInput
                        className="adminContainerRequestInviteInput"
                        value={requestEmail}
                        onValueChange={(value: string) => {
                            this.setState({ requestEmail: value, requestEmailError: '' });
                        }}
                        placeholder="Email"
                        onEnter={(): void => {
                            this.requestInviteNoEmail();
                        }}
                    />
                    <DefaultText className="adminContainerRequestInviteError" text={requestEmailError} />
                </div>
                <div className="adminContainer">
                    <DefaultText className="adminContainerHeader" text={`Invite not sent users (${notInvitedEmails.length})`} bold />
                    {notInvitedEmails.length > 0 ? (
                        <table className="adminContainerUserTable">
                            <tbody>
                                <tr>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                                {notInvitedEmails.map((e, idx) => (
                                    <tr key={`not-invited-email-${idx}`}>
                                        <td>{e}</td>
                                        <td>
                                            <DefaultButton 
                                                className="adminInviteUserButton"
                                                onClick={(): void => { this.sendInvite(e); }} 
                                                text="Invite" 
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <DefaultText text="No users." />
                    )}
                </div>
                <div className="adminContainer">
                    <DefaultText className="adminContainerHeader" text={`Invited but not accepted users (${inviteNotAcceptedEmails.length})`} bold />
                    {inviteNotAcceptedEmails.length > 0 ? (
                        <table className="adminContainerUserTable">
                            <tbody>
                                <tr>
                                    <th>Email</th>
                                </tr>
                                {inviteNotAcceptedEmails.map((e, idx) => (
                                    <tr key={`invite-not-accepted-email-${idx}`}>
                                        <td>{e}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <DefaultText text="No users." />
                    )}
                </div>
                <div className="adminContainer">
                    <DefaultText className="adminContainerHeader" text="Send custom email" bold />
                    {customEmailQueryOptions.map((q: { queryName: string; queryValue: string }, idx: number) => (
                        <div className="queryRow" key={`query-container-${idx}`}>
                            <DefaultTextInput
                                value={q.queryName}
                                placeholder="Query name"
                                onValueChange={(text: string) => {
                                    const newCustomEmailQueryOptions = this.state.customEmailQueryOptions;
                                    newCustomEmailQueryOptions[idx].queryName = text;
                                    this.setState({ customEmailQueryOptions: newCustomEmailQueryOptions, customEmailError: '' });
                                }}
                            />
                            <DefaultTextInput
                                value={q.queryValue}
                                placeholder="Query value"
                                onValueChange={(text: string) => {
                                    const newCustomEmailQueryOptions = this.state.customEmailQueryOptions;
                                    newCustomEmailQueryOptions[idx].queryValue = text;
                                    this.setState({ customEmailQueryOptions: newCustomEmailQueryOptions, customEmailError: '' });
                                }}
                            />
                        </div>
                    ))}
                    <DefaultButton text="Add Query" onClick={this.onAddQuery} />
                    <DefaultTextInput 
                        className="adminCustomHTMLInput"
                        value={customEmailSubject}
                        onValueChange={(text: string): void => {
                            this.setState({ customEmailSubject: text, customEmailError: '' })
                        }}
                        placeholder="Custom Email Subject"
                    />
                    <DefaultTextInput 
                        className="adminCustomHTMLInput"
                        value={customEmailHTML}
                        onValueChange={(text: string): void => {
                            this.setState({ customEmailHTML: text.replace('\n', '').replace('\t', ''), customEmailError: '' })
                        }}
                        placeholder="Custom HTML"
                    />
                    <DefaultText className="adminCustomEmailError" text={customEmailError} />
                    <DefaultButton
                        text="Send"
                        disabled={!customEmailSubject || !customEmailHTML}
                        onClick={this.sendEmail}
                    />
                </div>
            </div>
        )
    }
}

export default AdminScreen;