import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import MemoryCard from '../components/MemoryCard';
import { MemoryGridViewType } from '../utils/constants';
import { Memory } from '../utils/types';
import { isYoutubeVideoURL } from '../utils/helpers';
import '../styles/views/MemoryGrid.scss';
import DefaultText from '../components/default/DefaultText';

type MemoryGridProps = {
    sortMemoriesBy: string;
    memories: Array<Memory>;
    loadMoreMemories: () => void;
    totalNumMemories: number;
    onSelectMemory: (memory: Memory) => void;
    isSearchResult: boolean;
};

type MemoryGridState = any;

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

class MemoryGrid extends React.Component<MemoryGridProps, MemoryGridState> {
    render(): JSX.Element {
        const { memories, loadMoreMemories, totalNumMemories, onSelectMemory, sortMemoriesBy, isSearchResult } = this.props;

        let gridContent = undefined;

        if (!isSearchResult) {
            const splitBySortType: { [key: string]: Array<Memory> } = {};
            for (let i = 0; i < memories.length; i++) {
                const createdAtDate = new Date(memories[i].createdAt);
                let key = ''
                if (sortMemoriesBy === MemoryGridViewType.Date) {
                    key = `${monthNames[createdAtDate.getMonth()]} ${createdAtDate.getFullYear()}`;
                } else if (sortMemoriesBy === MemoryGridViewType.Category) {
                    key = memories[i].fullTopic ? memories[i].fullTopic.split('/')[1] : memories[i].topic;
                }

                if (!splitBySortType[key]) {
                    splitBySortType[key] = [];
                }

                splitBySortType[key].push(memories[i]);
            }

            gridContent = (
                Object.keys(splitBySortType).map((k, idx) => (
                    <React.Fragment key={`sort-grid-container-${idx}`}>
                        <DefaultText className="memoryGridSortHeader" text={k} bold />
                        <div className="memoryGrid">
                            {splitBySortType[k].map((m, idx) => (
                                <MemoryCard
                                    key={`memory-${idx}`}
                                    className={`memoryGridCard ${ (m.img || (m.url && isYoutubeVideoURL(m.url) )) ? 'memoryGridImgCard' : 'memoryGridURLCard' }`}
                                    memory={m}
                                    onClick={(): void => { onSelectMemory(m) }}
                                />
                            ))}
                        </div>
                    </React.Fragment>
                ))
            );
        } else {
            gridContent = (
                <div className="memoryGrid">
                    {memories.map((m, idx) => (
                        <MemoryCard
                            key={`memory-${idx}`}
                            className={`memoryGridCard ${ (m.img || (m.url && isYoutubeVideoURL(m.url) )) ? 'memoryGridImgCard' : 'memoryGridURLCard' }`}
                            memory={m}
                            onClick={(): void => { onSelectMemory(m) }}
                        />
                    ))}
                </div>
            );
        }

        return (
            <React.Fragment>
                <InfiniteScroll
                    dataLength={memories.length}
                    next={(): void => {
                        loadMoreMemories();
                    }}
                    hasMore={memories.length < totalNumMemories}
                    loader={<div className="memoryGridLoaderContainer"></div>}
                >
                    {gridContent}
                </InfiniteScroll>
            </React.Fragment>
        )
    }
}

export default MemoryGrid;