export const MemoryGridViewType = {
    Date: 'Date',
    Category: 'Category',
    // Explore: 'Explore'
};

export const MemoryType = {
    URL: 'URL',
    Image: 'Image',
}

export const BlobColors = [
    '#d79568',
    '#d7698a',
    '#736ab0',
    '#6d8fca',
    '#6cb7ce',
    '#6fc9bf',
    '#68b27a',
    '#c6c66e',
    '#c4b470',
    '#c1986f',
    '#ce6767'
];

export const MAX_NUM_TAGS = 6;
export const MAX_TITLE_LENGTH = 200;
export const MAX_TAG_LENGTH = 25;