// === React Imports ===
import React from 'react';
import { IoIosClose } from "react-icons/io";
import DefaultText from './default/DefaultText';

// === Custom Imports ===
import '../styles/components/AddTag.scss';

type AddTagProps = {
  tag: string;
  onRemoveTag?: (tag: string) => void;
  className?: string;
  locked?: boolean;
}

const AddTag = ({ tag, onRemoveTag, className, locked }: AddTagProps): JSX.Element => (
    <div className={`addTagContainer ${className}`}>
        <DefaultText className="addTagText" text={`#${tag}`} />
        {!locked && <IoIosClose className='addTagCloseImg' onClick={(): void => { onRemoveTag && onRemoveTag(tag) }} />}
    </div>
);

export default AddTag;
