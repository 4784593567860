// === React Imports ===
import React from 'react';
import DefaultText from './default/DefaultText';
import { Topic } from '../utils/types';
import { toPercentage } from '../utils/helpers';

// === Custom Imports ===
import '../styles/components/SelectTopic.scss';

type SelectTopicProps = {
  topic: Topic;
  selected: boolean;
  onSelectTopic: (topic: string) => void;
  className?: string;
  hideConfidence?: boolean;
}

function getPercentageClassName(percentage: number): string {
    if (percentage < 0.3) {
        return 'selectTopicPercentageRed';
    } else if (percentage < 0.6) {
        return 'selectTopicPercentageYellow'
    } else {
        return 'selectTopicPercentageGreen'
    }
}

const SelectTopic = ({ topic, selected, onSelectTopic, className, hideConfidence }: SelectTopicProps): JSX.Element => (
    <div className={`selectTopicContainer ${selected && 'selectTopicContainerSelected'} ${className}`} onClick={(): void => { onSelectTopic(topic.name); }}>
        <DefaultText className="selectTopicText" text={topic.name} />
        {!hideConfidence && (
            <DefaultText 
                className={`${getPercentageClassName(topic.confidence)} selectTopicPercentage`} 
                text={toPercentage(topic.confidence)} 
                bold
            />
        )}
    </div>
);

export default SelectTopic;
