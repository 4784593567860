// === React Imports ===
import React from 'react';

// === Custom Imports ===
import DefaultText from './DefaultText';
import '../../styles/components/default/DefaultButton.scss';

type DefaultButtonProps = {
  text?: string;
  className?: string;
  textClassName?: string;
  style?: any;
  onClick: () => void;
  disabled?: boolean;
  img?: any;
  reactIcon?: any;
  bold?: any;
}

const DefaultButton = ({
    text,
    className,
    textClassName,
    style,
    onClick,
    disabled,
    img,
    reactIcon,
    bold
}: DefaultButtonProps): JSX.Element => (
    <button
        type="button"
        className={`defaultButton ${disabled ? 'defaultInactiveButton' : 'defaultActiveButton'} ${className}`}
        style={style}
        onMouseDown={(): void => {
            if (!disabled) onClick();
        }}
    >
        {img && <img className="defaultButtonImg" src={img} alt="Button" />}
        {reactIcon}
        <DefaultText className={`defaultButtonText ${textClassName}`} text={text ? text : ''} bold={bold} />
    </button>
);

export default DefaultButton;
