// === React Imports ===
import React from 'react';

// === Custom Imports ===
import '../../styles/components/default/DefaultTextArea.scss';

type DefaultTextAreaProps = {
  inputRef?: (ref: any) => void;
  placeholder?: string;
  className?: string;
  style?: any;
  type?: any;
  spellCheck?: boolean;
  value: string | number | boolean;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  disabledCharCodes?: Array<number>;
  onEnter?: () => void;
  maxLength?: number;
  onBlur?: () => void;
  onFocus?: () => void;
}

type DefaultTextAreaState = {
  focused: boolean;
}

class DefaultTextArea extends React.Component<DefaultTextAreaProps, DefaultTextAreaState> {
    constructor(props: DefaultTextAreaProps) {
        super(props);
        this.state = {
            focused: false
        }
    }

    render(): JSX.Element {
        const {
            inputRef,
            placeholder,
            className,
            style,
            type,
            spellCheck,
            value,
            onValueChange,
            disabled,
            disabledCharCodes,
            onEnter,
            maxLength,
            onBlur,
            onFocus,
        } = this.props;

        return (
            <div className={`defaultTextAreaContainer ${className}`}>
                <textarea
                    ref={inputRef}
                    className={`defaultTextArea ${disabled && 'defaultTextAreaDisabled'}`}
                    style={style}
                    value={`${value}`}
                    placeholder={placeholder}
                    spellCheck={spellCheck}
                    onChange={(e: any): void => { onValueChange(e.target.value) }}
                    autoComplete="new-password"
                    disabled={disabled}
                    maxLength={maxLength}
                    onBlur={(): void => {
                        this.setState({ focused: false });
                        onBlur && onBlur();
                    }}
                    onFocus={(): void => {
                        this.setState({ focused: true });
                        onFocus && onFocus();
                    }}
                    onKeyPress={(e: any) => { 
                        if (e.charCode === 13) {
                            onEnter && onEnter();
                            return true;
                        }

                        if (disabledCharCodes && disabledCharCodes.includes(e.charCode)) {
                            e.preventDefault();
                            return false;
                        }

                        return true;
                    }}
                />
            </div>
        )
    }
}

export default DefaultTextArea;
