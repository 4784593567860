// === React Imports ===
import React from 'react';

// === Custom Imports ===
import '../../styles/components/default/DefaultText.scss';

type DefaultTextProps = {
  text: string | boolean | number;
  className?: string;
  style?: any;
  onClick?: () => void;
  bold?: boolean;
  italic?: boolean;
  medium?: boolean;
}

const DefaultText = ({ text, className, style, onClick, bold, italic, medium }: DefaultTextProps): JSX.Element => (
    <p 
        className={`defaultText ${className}`} 
        style={{
            ...style, 
            ...bold ? { fontWeight: '700' } : {}, 
            ...italic ? { fontStyle: 'italic' } : {},
            ...medium ? { fontWeight: '500' } : {} 
        }} 
        onClick={onClick && onClick}
    >
        {text}
    </p>
);

export default DefaultText;
