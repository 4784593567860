import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Api from './utils/api';
import { Routes } from './utils/routes';
import { Errors } from './utils/errors';
import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import UnauthorizedScreen from './screens/UnauthorizedScreen';
import UnsubscribeScreen from './screens/UnsubscribeScreen';
import AdminScreen from './screens/AdminScreen';
import './App.scss';

type AppProps = any;
type AppState = {
  loading: boolean;
};

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount(): void {
        if (window.location.pathname === Routes.UNAUTHORIZED) {
            this.setState({ loading: false });
            return;
        }

        const cookie = this.getCookie("memento-jwt");
        if (cookie) {
            // Check if token expired
            Api.checkJWTToken()
                .then((res) => {
                    if (window.location.pathname === Routes.LOGIN) {
                        window.location.assign(Routes.HOME);
                        return;
                    }
          
                    Api.setLoggedInUser(res.data.user)
                    this.setState({ loading: false });
                })
                .catch((err) => {
                    if (err.response.status === 403 && err.response.data.response === Errors.UnauthorizedException) {
                        if (window.location.pathname !== Routes.LOGIN) {
                            window.location.assign(Routes.LOGIN);
                        } else {
                            this.setState({ loading: false });
                        }
                    }
                });
        } else {
            if (window.location.pathname !== Routes.LOGIN) {
                window.location.assign(Routes.LOGIN);
            } else {
                this.setState({ loading: false });
            }
        }
    }

  getCookie = (name: string): string | null => {
      const escape = name.replace(/([.*+?${}()|\]\\])/g, '\\$1');
      const match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape + '=([^;]*)'));
      return match ? match[1] : null;
  }

  render(): JSX.Element {
      const { loading } = this.state;

      if (loading) {
          return <div />;
      }

      return (
          <Router>
              <Switch>
                  <Route path={Routes.LOGIN} exact component={LoginScreen} />
                  <Route path={Routes.HOME} exact component={HomeScreen} />
                  {Api.loggedInUser && Api.loggedInUser.email === 'andresfernandokim@gmail.com' && (
                      <Route path={Routes.ADMIN} exact component={AdminScreen} />
                  )}
                  <Route path={Routes.UNSUBSCRIBE} exact component={UnsubscribeScreen} />
                  <Route path={Routes.UNAUTHORIZED} exact component={UnauthorizedScreen} />
                  <Route component={HomeScreen} />
              </Switch>
          </Router>
      )
  }
}

export default App;
