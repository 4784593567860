// === React Imports ===
import React from 'react';
import { spline } from "@georgedoescode/spline";
import SimplexNoise from "simplex-noise";

type BlobProps = {
    className?: string;
    color?: string;
    width?: number;
    height?: number;
    noiseOffset?: number;
    canvasWidth?: number;
    canvasHeight?: number;
};
type BlobState = any;

type Point = {
    x: number;
    y: number;
    originX: number;
    originY: number;
    noiseOffsetX: number;
    noiseOffsetY: number;
}

function map(n: number, start1: number, end1: number, start2: number, end2: number): number {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

const simplex = new SimplexNoise();
const noiseStep = 0.002; // how fast time goes
const defaultWidth = 200;
const defaultHeight = 200;
const defaultNoiseOffset = 10;

class Blob extends React.Component<BlobProps, BlobState> {
    blobRef: any;
    points: Array<Point>;

    constructor(props: BlobProps) {
        super(props);
        this.points = this.createPoints();
    }

    componentDidMount(): void {
        this.animateBlobs()
    }

    animateBlobs = (): void => {
        const { noiseOffset } = this.props;
        for (let i = 0; i <this.points.length; i++) {
            const point = this.points[i];
            const nX = simplex.noise2D(point.noiseOffsetX, point.noiseOffsetX);
            const nY = simplex.noise2D(point.noiseOffsetY, point.noiseOffsetY);
            // map this noise value to a new value, somewhere between it's original location -offset and it's original location + offset
            const x = map(nX, -1, 1, point.originX - (noiseOffset || defaultNoiseOffset), point.originX + (noiseOffset || defaultNoiseOffset));
            const y = map(nY, -1, 1, point.originY - (noiseOffset || defaultNoiseOffset), point.originY + (noiseOffset || defaultNoiseOffset));
            // update the point's current coordinates
            point.x = x;
            point.y = y;

            // progress the point's x, y values through "time"
            point.noiseOffsetX += noiseStep;
            point.noiseOffsetY += noiseStep;
        }

        if (this.blobRef) {
            this.blobRef.setAttribute("d", spline(this.points, 1, true))
        }

        const requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame;
        requestAnimationFrame(this.animateBlobs)
    }

    createPoints = (): Array<Point> => {
        const { width, height, noiseOffset } = this.props;
        const points = [];
        const numPoints = 6;
        const angleStep = (Math.PI * 2) / numPoints;
        const rad = ((width || defaultWidth) / 2) - (noiseOffset || defaultNoiseOffset);
  
        for (let i = 1; i <= numPoints; i++) {
            const theta = i * angleStep;
            const x = ((width || defaultWidth) / 2) + Math.cos(theta) * rad;
            const y = ((height || defaultHeight) / 2) + Math.sin(theta) * rad;
  
            // store the point
            points.push({
                x: x,
                y: y,
                originX: x,
                originY: y,
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000,
            });
        }
  
        return points;
    }

    render(): JSX.Element {
        const { className, color, canvasWidth, canvasHeight } = this.props;
        return (
            <svg className={className} viewBox={`0 0 ${canvasWidth || 500} ${canvasHeight || 500}`}>
                <path ref={(ref: any) => { this.blobRef = ref }} d="" fill={color || '#fff'}></path>
            </svg>
        )
    }
}

export default Blob;