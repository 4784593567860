// === React Imports ===
import React from 'react';

// === Custom Imports ===
import '../../styles/components/default/DefaultLink.scss';

type DefaultLinkProps = {
  text: string | boolean | number;
  className?: string;
  style?: any;
  onClick?: () => void;
  bold?: boolean;
  selected?: boolean;
}

const DefaultLink = ({ text, className, style, onClick, bold, selected }: DefaultLinkProps): JSX.Element => (
    <a className={`defaultLink ${selected && 'defaultLinkSelected'} ${className}`} style={{...style, ...bold ? { fontFamily: 'leon-sans-bold' } : {} }} onClick={onClick && onClick}>
        {text}
    </a>
);

export default DefaultLink;
