import React from 'react';
import { IoClose } from "react-icons/io5";
import CopyToClipboard from 'react-copy-to-clipboard';
import DefaultText from '../default/DefaultText';
import DefaultTextInput from '../default/DefaultTextInput';
import DefaultButton from '../default/DefaultButton';
import Api from '../../utils/api';
// import { Errors } from '../../utils/errors';
import '../../styles/components/modals/CreateMemoryModal.scss';
import '../../styles/components/modals/ChromeExtensionTokenModal.scss';
import { openInNewTab } from '../../utils/helpers';

type ChromeExtensionTokenModalProps = {
    onClose: () => void;
};

type ChromeExtensionTokenModalState = {
    token: string;
    copied: boolean;
};

class ChromeExtensionTokenModal extends React.Component<ChromeExtensionTokenModalProps, ChromeExtensionTokenModalState> {
    constructor(props: ChromeExtensionTokenModalProps) {
        super(props);
        this.state = {
            token: '',
            copied: false
        }
    }

    onGenerateNewToken = (): void => {
        Api.createChromeExtensionToken()
            .then((res) => {
                this.setState({ token: res.data.chromeExtensionToken });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    render(): JSX.Element {
        const { onClose } = this.props;
        const { token, copied } = this.state;

        return (
            <div>
                <div className="createMemoryModalBackground" onClick={onClose} />
                <div className="createMemoryModalContainer">
                    <div className="createMemoryModalContentContainer">
                        <IoClose className="createMemoryModalClose" onClick={onClose} />
                        <DefaultText className="chromeExtensionTokenModalHeader" text={`Memento Chrome Extension`} bold />
                        <span className="chromeExtensionTokenModalBlurb">1. Download the <a className="chromeExtensionTokenLink" onClick={(): void => { openInNewTab('https://chrome.google.com/webstore/detail/memento/djcdckamihmkfclojigjpccfkgliijgo?hl=en') }}>Memento Chrome Extension</a>.</span>
                        <p className="chromeExtensionTokenModalBlurb">2. Generate a token and paste it in the chrome extension to link your account.</p>
                        <p className="chromeExtensionTokenModalBlurb">3. Use the chrome extension to save memories!</p>
                        {token && (
                            <DefaultText 
                                className="chromeExtensionTokenStoreMsg"
                                text={"Please store the token somewhere safe. This token will not be retrievable again."}
                            />
                        )}
                        <div className="chromeExtensionTokenInputContainer">
                            <DefaultTextInput
                                className="chromeExtensionTokenInput"
                                value={token}
                                onValueChange={(): void => { return; }}
                                disabled
                            />
                            <CopyToClipboard text={token}>
                                <DefaultButton 
                                    text={copied ? "Copied" : "Copy"}
                                    onClick={(): void => { this.setState({ copied: true }) }}
                                    disabled={!token}
                                />
                            </CopyToClipboard>
                        </div>
                        <div className="createMemoryModalActionButtonContainer">
                            <DefaultButton 
                                className="chromeExtensionTokenCloseButton" 
                                text="Close" 
                                onClick={onClose}
                            />
                            <DefaultButton 
                                className="chromeExtensionTokenGenerateButton" 
                                text="Generate" 
                                onClick={this.onGenerateNewToken}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChromeExtensionTokenModal;
  